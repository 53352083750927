import { usePollingEffect } from "../../useAPI";
import { constVoid } from "fp-ts/function";
import * as uploadApi from "../../UploadDocuments/api";
import { option } from "fp-ts";
import { Dialog, SpinnerIcon } from "design-system";
import { useFormatMessage } from "../../intl";

type Props = {
  onDismiss: () => void;
};

export const WaitingUploadedStatusDialog = (props: Props) => {
  const formatMessage = useFormatMessage();

  usePollingEffect(
    uploadApi.mobileStatus,
    {
      intervalMS: 3000,
      shouldPollingContinue: result => result.status !== "UploadCompleted",
      onError: constVoid,
      onSuccess: result => {
        if (result.status === "UploadCompleted") {
          props.onDismiss();
        }
      },
    },
    { coApplicant: option.none }
  );

  return (
    <Dialog
      variant="center"
      title={formatMessage("Uploading")}
      icon={SpinnerIcon}
      onDismiss={option.none}
      actions={[]}
      size="medium"
    />
  );
};
