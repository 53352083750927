import { Body, LocalizedString, Stack } from "design-system";
import { NonEmptyString } from "io-ts-types/NonEmptyString";
import * as api from "../api";
import { UploadDocumentItem } from "./UploadDocumentItem";
import { option } from "fp-ts";
import { DocumentNote } from "./DocumentNote";

type Props = {
  subSection: api.UploadedDocumentData[];
  subSectionName: LocalizedString;
  showMandatoryError: boolean;
  onSelectDocument: (document: api.UploadedDocumentData) => void;
  onRemoveDocument: (docId: NonEmptyString) => void;
};

export function UploadDocumentsSubsection(props: Props) {
  const renderDocumentItemAndNote = (document: api.UploadedDocumentData) => {
    return (
      <>
        <UploadDocumentItem
          document={document}
          showMandatoryError={props.showMandatoryError}
          onSelectItem={() => props.onSelectDocument(document)}
          onRemoveItem={props.onRemoveDocument}
        />
        {option.isSome(document.uploadNote) &&
          document.uploadNote.value.length > 0 && (
            <DocumentNote note={document.uploadNote.value} />
          )}
      </>
    );
  };

  return (
    <Stack column grow units={4}>
      <Body size="small" weight="medium">
        {props.subSectionName}
      </Body>

      <Stack column grow units={2}>
        {props.subSection.map(document => renderDocumentItemAndNote(document))}
      </Stack>
    </Stack>
  );
}
