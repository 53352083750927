import {
  AlertDialog,
  Body,
  Box,
  Button,
  convertToBlob,
  downloadFile,
  InlineMessage,
  LocalizedString,
  Space,
  Stack,
  unsafeLocalizedString,
  UploadIcon,
  useDeviceUA,
  useIsMobileLayout,
} from "design-system";
import { option, taskEither } from "fp-ts";
import { Option } from "fp-ts/Option";
import { constVoid, pipe } from "fp-ts/function";
import { useState } from "react";
import { palette } from "design-system/lib/styleConstants";
import { useFormatMessage } from "../../intl";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import * as api from "../api";
import { DocumentViewDialog } from "../../Common/Dialogs/DocumentViewDialog/DocumentViewDialog";
import { getDocumentContentSL, DocumentMime } from "../../Common/documentAPI";
import { getOnlyDownloadMimes } from "../../Common/documentDomain";
import { useCommand } from "../../useAPI";
import { foldRequiredDocumentStatus } from "../domain";
import moment from "moment";
import { messageTimeFormat } from "../../MortgageDashboard/Communication/util";
import * as classes from "./UploadDocumentItem.treat";

type Props = {
  document: api.UploadedDocumentData;
  showMandatoryError: boolean;
  onSelectItem: () => void;
  onRemoveItem: (docId: NonEmptyString) => void;
};

function DownloadItem(props: {
  documentId: NonEmptyString;
  documentName: LocalizedString;
  documentMime: Option<DocumentMime>;
  onRemoveItem: (docId: NonEmptyString) => void;
  uploadedDate: Option<number>;
}) {
  const isMobileLayout = useIsMobileLayout();
  const formatMessage = useFormatMessage();
  const [isDownloadDialogOpen, setDownloadDialogOpen] = useState<boolean>(
    false
  );
  const [isRemoveDialogOpen, setRemoveDialogOpen] = useState<boolean>(false);
  const documentContent = useCommand(getDocumentContentSL);
  const { isSafari } = useDeviceUA();

  const download = pipe(
    {
      documentID: props.documentId,
    },
    documentContent,
    taskEither.map(docContent => {
      const url = convertToBlob(docContent.content, docContent.mimeType);

      downloadFile(url, docContent.filename);
    })
  );

  const onDocumentNameClick = () =>
    pipe(
      props.documentMime,
      option.fold(
        constVoid, // should never happen, because this component is rendered when docId is not null. When docId is not null, mimeType should never be null
        documentMime => {
          // temporary fix, until preview of onlyDownloadMimes types is implemented
          if (getOnlyDownloadMimes(isSafari).includes(documentMime)) {
            return download();
          }
          return setDownloadDialogOpen(true);
        }
      )
    );

  return (
    <>
      <Stack column={isMobileLayout} grow shrink vAlignContent="center">
        <Box grow shrink>
          <Button
            className={classes.button}
            variant="text"
            size="default"
            label={unsafeLocalizedString(
              props.documentName +
                pipe(
                  props.uploadedDate,
                  option.fold(
                    () => "" as LocalizedString,
                    date =>
                      unsafeLocalizedString(
                        " | " + moment(date).format(messageTimeFormat)
                      )
                  )
                )
            )}
            action={onDocumentNameClick}
          />
        </Box>
        {!isMobileLayout && <Space units={4} />}
        <Box>
          <Button
            variant="text"
            size="default"
            label={formatMessage("StandardLoan.UploadDocuments.remove")}
            action={() => setRemoveDialogOpen(true)}
          />
        </Box>
      </Stack>
      {isDownloadDialogOpen && (
        <DocumentViewDialog
          documentId={props.documentId}
          onClose={() => setDownloadDialogOpen(false)}
          productType="SL"
        />
      )}
      {isRemoveDialogOpen && (
        <AlertDialog
          type="disruptive"
          title={formatMessage("StandardLoan.UploadDocuments.remove.title")}
          message={formatMessage(
            "StandardLoan.UploadDocuments.remove.subtitle"
          )}
          onDismiss={() => {
            setRemoveDialogOpen(false);
          }}
          onCancel={() => {
            setRemoveDialogOpen(false);
          }}
          onConfirm={() => {
            setRemoveDialogOpen(false);
            props.onRemoveItem(props.documentId);
          }}
          cancelLabel={formatMessage("Cancel")}
          confirmLabel={formatMessage(
            "StandardLoan.UploadDocuments.remove.confirm"
          )}
        />
      )}
    </>
  );
}

function UploadItem(props: {
  document: api.UploadedDocumentData;
  onSelectItem: () => void;
}) {
  const isMobileLayout = useIsMobileLayout();
  const formatMessage = useFormatMessage();

  const uploadButton = (
    <Button
      variant="text"
      icon={UploadIcon}
      size="default"
      label={formatMessage("Upload")}
      action={props.onSelectItem}
    />
  );
  const uploading = (
    <Body size="small" weight="regular" color={palette.neutral700}>
      {formatMessage("Uploading")}
    </Body>
  );

  return (
    <Box column={isMobileLayout} grow shrink vAlignContent="center">
      <Box grow shrink>
        <Body size="small" weight="regular" color={palette.neutral700}>
          {unsafeLocalizedString(
            props.document.documentName +
              pipe(
                props.document.uploadedTimestamp,
                option.fold(
                  () => "" as LocalizedString,
                  date =>
                    unsafeLocalizedString(
                      " | " + moment(date).format(messageTimeFormat)
                    )
                )
              )
          )}
        </Body>
      </Box>

      {!isMobileLayout && <Space units={4} />}
      <Box>
        {pipe(
          props.document.requiredDocumentStatus,
          foldRequiredDocumentStatus({
            whenNew: () => uploadButton,
            whenUploaded: () => uploadButton,
            whenUploadedByClient: () => uploadButton,
            whenUploading: () => uploading,
            whenUploadingByClient: () => uploading,
            whenSealing: () => uploading,
          })
        )}
      </Box>
    </Box>
  );
}

export function UploadDocumentItem(props: Props) {
  const formatMessage = useFormatMessage();
  return pipe(
    props.document.docId,
    option.fold(
      () => (
        <>
          <UploadItem
            onSelectItem={props.onSelectItem}
            document={props.document}
          />
          {props.showMandatoryError && (
            <InlineMessage
              message={formatMessage("Form.fieldError.requiredFile")}
              type="error"
              size="small"
            />
          )}
        </>
      ),
      id => (
        <DownloadItem
          documentId={id}
          documentName={props.document.documentName}
          documentMime={props.document.mimeType}
          uploadedDate={props.document.uploadedTimestamp}
          onRemoveItem={props.onRemoveItem}
        />
      )
    )
  );
}
