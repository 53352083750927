import { Heading, LocalizedString, Stack } from "design-system";
import * as api from "../api";
import { LocaleKey, useFormatMessage } from "../../intl";
import { UploadDocumentsSubsection } from "./UploadDocumentsSubsection";
import { NonEmptyString } from "io-ts-types/NonEmptyString";

type Props = {
  section: api.UploadedDocumentData[];
  sectionName: api.SectionType;
  showMandatoryError: boolean;
  onSelectDocument: (document: api.UploadedDocumentData) => void;
  onRemoveDocument: (docId: NonEmptyString) => void;
};

export function UploadDocumentsSection(props: Props) {
  const formatMessage = useFormatMessage();

  const subSections = extractSubSectionsFromSection(props.section);

  return (
    <Stack grow column units={2}>
      <Heading size="x-small" weight="medium">
        {formatMessage(getSectionLabel(props.sectionName))}
      </Heading>
      <Stack column grow units={10}>
        {subSections.map(subSection => (
          <UploadDocumentsSubsection
            subSection={props.section.filter(
              document => document.subSection === subSection
            )}
            subSectionName={subSection}
            showMandatoryError={props.showMandatoryError}
            onSelectDocument={props.onSelectDocument}
            onRemoveDocument={props.onRemoveDocument}
          />
        ))}
      </Stack>
    </Stack>
  );
}

function getSectionLabel(section: api.SectionType): LocaleKey {
  switch (section) {
    case "MainIncome":
      return "StandardLoan.UploadDocuments.MainIncome.title";
    case "AdditionalIncome":
      return "StandardLoan.UploadDocuments.AdditionalIncome.title";
    case "Refinancing":
      return "StandardLoan.UploadDocuments.Refinancing.title";
    case "OptionalDocuments":
      return "StandardLoan.UploadDocuments.OptionalDocuments.title";
    case "NewRequiredDocuments":
      return "StandardLoan.UploadDocuments.NewRequiredDocuments.title";
  }
}

function extractSubSectionsFromSection(
  section: api.UploadedDocumentData[]
): LocalizedString[] {
  const found: LocalizedString[] = [];

  section.forEach(
    document =>
      !found.includes(document.subSection) && found.push(document.subSection)
  );

  return found;
}
