import { useCommand } from "../../useAPI";
import * as uKontoApi from "../../UKontoFirstPart/UKontoPackagesSelection/api";
import { useEffect, useState } from "react";
import { constVoid, pipe } from "fp-ts/function";
import { option, taskEither } from "fp-ts";
import { VirtualCardsCommon } from "../../VirtualCards/VirtualCardsCommon";
import { useAppContext } from "../../useAppContext";

type Props = {
  onSubmit: () => unknown;
  onChange: (data: any) => unknown;
  onBack: () => unknown;
};
export const VirtualCardsSL = (props: Props) => {
  const {
    config: { enableMastercardImpl },
  } = useAppContext();
  const getFeatureWhitelist = useCommand(uKontoApi.getFeatureWhitelist);
  const [mastercardWhitelist, setMastercardWhitelist] = useState(false);
  const showMastercard = enableMastercardImpl || mastercardWhitelist;

  useEffect(() => {
    pipe(
      getFeatureWhitelist({ feature: "enableMastercardImpl" }),
      taskEither.bimap(constVoid, isMasterCardWhitelisted =>
        setMastercardWhitelist(isMasterCardWhitelisted)
      )
    )();
  }, []);

  return (
    <VirtualCardsCommon
      isCf
      isFromChild={false}
      physicalCardSelected={option.some(true)}
      onSubmit={props.onSubmit}
      onChange={props.onChange}
      onBack={props.onBack}
      showMastercard={showMastercard}
    />
  );
};
