import { boolean, option } from "fp-ts";
import { Option } from "fp-ts/Option";
import { Reader } from "fp-ts/Reader";
import {
  CreditBureauStatus,
  GenericLoanResponseOutput,
  LFStatus,
  LoanOffer,
  OfferTypeDecision,
  OfferTypeOutput,
  ParallelFlowParameters,
  RestoreNewestLoanApplicationChooseOfferToKycStepsOutput,
  RestoreNewestLoanApplicationMicroPaymentStepsOutput,
  RestoreNewestLoanApplicationOtherStepsOutput,
  RestoreNewestLoanApplicationOutput,
  RestoreNewestLoanApplicationPersonalDataStepOutput,
  SignatureStatus,
  StartLoanApplicationInput,
  TransactionsInfo,
} from "./api";
import { ReworkOldValuesOutput, ReworkStep } from "./Rework/api";
import { RefuseExistingClientReason } from "./domain";
import { constant, pipe } from "fp-ts/function";
import { OfferType } from "./OfferList/OfferList";
import { PersonalDataFromOutput } from "./Confirmation/domain";
import { CPIAdditionalQuestions, UKontoPackageType } from "./CustomerOffer/api";
import {
  ApplicationProductType,
  ApplicationStatus,
} from "../Common/ProcessStoreList/api";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { ExtractedDataResult } from "../UploadDocuments/types";
import { mergeExtractDataDocumentsOutput } from "../UploadDocuments/utils";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { LocaleKey } from "../intl";

export interface Rework {
  needContractsUpdate: boolean;
  reworkSteps: Option<NonEmptyArray<ReworkStep>>;
  oldValues: Option<ReworkOldValuesOutput>;
  newValues: Option<RestoreNewestLoanApplicationOtherStepsOutput>;
  reworkAll: boolean;
}

interface LoadingState {
  type: "Loading";
}

export function loading(): LoadingState {
  return { type: "Loading" };
}

interface LandingState {
  type: "Landing";
}

interface MaxNumberChangesReachedState {
  type: "MaxNumberChangesReached";
}

export function landing(): LandingState {
  return { type: "Landing" };
}

export function maxNumberChangesReached(): MaxNumberChangesReachedState {
  return { type: "MaxNumberChangesReached" };
}

interface PhoneAndEmailVerificationState {
  type: "PhoneAndEmailVerification";
  payload: {
    loanOffer: Option<LoanOffer>;
  };
}

interface Select3PState {
  type: "Select3P";
  payload: {
    loanOffer: LoanOffer;
  };
}

interface AddPhoneNumberState {
  type: "AddPhoneNumber";
}

export function verifyPhoneAndEmail(
  loanOffer: Option<LoanOffer>
): PhoneAndEmailVerificationState {
  return {
    type: "PhoneAndEmailVerification",
    payload: { loanOffer },
  };
}

export function select3P(loanOffer: LoanOffer): Select3PState {
  return {
    type: "Select3P",
    payload: { loanOffer },
  };
}

interface UploadIDState {
  type: "UploadID";
  payload: { loanOffer: LoanOffer };
}

export function uploadId(loanOffer: LoanOffer): UploadIDState {
  return { type: "UploadID", payload: { loanOffer } };
}

interface ExistingClientSuspenseState {
  type: "ExistingClientSuspense";
  payload: { loanOffer: Option<LoanOffer>; isApplicationExisting: boolean };
}

export function existingClientSuspense(
  loanOffer: Option<LoanOffer>,
  isApplicationExisting: boolean
): ExistingClientSuspenseState {
  return {
    type: "ExistingClientSuspense",
    payload: { loanOffer, isApplicationExisting },
  };
}

interface CreditChecksState {
  type: "CreditChecks";
  payload: {
    loanOffer: LoanOffer;
    initialConsent: Option<StartLoanApplicationInput>;
    getIncome: boolean;
    rework: Rework;
    hasRefinancingCredits: boolean;
    cpiAdditionalQuestions: Option<CPIAdditionalQuestions>;
  };
}

export function creditChecks(
  loanOffer: LoanOffer,
  initialConsent: Option<StartLoanApplicationInput>,
  getIncome: boolean,
  rework: Rework,
  hasRefinancingCredits: boolean,
  cpiAdditionalQuestions: Option<CPIAdditionalQuestions>
): CreditChecksState {
  return {
    type: "CreditChecks",
    payload: {
      loanOffer,
      initialConsent,
      getIncome,
      rework,
      hasRefinancingCredits,
      cpiAdditionalQuestions,
    },
  };
}

interface ExistingClientState {
  type: "ExistingClient";
  reason: RefuseExistingClientReason;
}

export function existingClient(
  reason: RefuseExistingClientReason
): ExistingClientState {
  return { type: "ExistingClient", reason };
}

interface ExistingApplication3PErrorState {
  type: "ExistingApplication3PError";
}

export function existingApplication3PError(): ExistingApplication3PErrorState {
  return { type: "ExistingApplication3PError" };
}

type RestoreApplicationPayload =
  | {
      restoredData: RestoreNewestLoanApplicationOutput;
      applicationStatus: Option<ApplicationStatus>;
      loanOffer: Option<LoanOffer>;
      rework: Rework;
      isErrorFetchingRestoredData: false;
      parallelFlowParameters: ParallelFlowParameters;
      cardProviderChange: boolean;
    }
  | {
      isErrorFetchingRestoredData: true;
      loanOffer: Option<LoanOffer>;
      parallelFlowParameters: ParallelFlowParameters;
    };

interface RestoreApplicationState {
  type: "RestoreApplication";
  payload: RestoreApplicationPayload;
}

export function restoreApplication(
  payload: RestoreApplicationPayload
): RestoreApplicationState {
  return {
    type: "RestoreApplication",
    payload,
  };
}

interface CustomerOfferState {
  type: "CustomerOffer";
  payload: {
    loanOffer: LoanOffer;
    personalDataOptions: PersonalDataFromOutput;
    initialConsent: StartLoanApplicationInput;
    cpiAdditionalQuestions: Option<CPIAdditionalQuestions>;
    rework: Rework;
  };
}

export function customerOffer(
  loanOffer: LoanOffer,
  personalDataOptions: PersonalDataFromOutput,
  initialConsent: StartLoanApplicationInput,
  cpiAdditionalQuestions: Option<CPIAdditionalQuestions>,
  rework: Rework
): CustomerOfferState {
  return {
    type: "CustomerOffer",
    payload: {
      loanOffer,
      personalDataOptions,
      initialConsent,
      cpiAdditionalQuestions,
      rework,
    },
  };
}

interface CheckAdditionalIncomesState {
  type: "CheckAdditionalIncomes";
  payload: {
    loanOffer: LoanOffer;
    personalDataOptions: PersonalDataFromOutput;
    hasRefinancingCredits: boolean;
    initialConsent: StartLoanApplicationInput;
    cpiAdditionalQuestions: Option<CPIAdditionalQuestions>;
    needsAdditionalIncome: Option<boolean>;
    hasAdditionalIncome: Option<boolean>;
    rework: Rework;
  };
}

function checkAdditionalIncomes(
  loanOffer: LoanOffer,
  personalDataOptions: PersonalDataFromOutput,
  hasRefinancingCredits: boolean,
  initialConsent: StartLoanApplicationInput,
  cpiAdditionalQuestions: Option<CPIAdditionalQuestions>,
  needsAdditionalIncome: Option<boolean>,
  hasAdditionalIncome: Option<boolean>,
  rework: Rework
): CheckAdditionalIncomesState {
  return {
    type: "CheckAdditionalIncomes",
    payload: {
      loanOffer,
      hasRefinancingCredits,
      personalDataOptions,
      initialConsent,
      cpiAdditionalQuestions,
      needsAdditionalIncome,
      hasAdditionalIncome,
      rework,
    },
  };
}

interface ExpensesAndAdditionalIncomeState {
  type: "ExpensesAndAdditionalIncome";
  payload: {
    loanOffer: LoanOffer;
    personalDataOptions: PersonalDataFromOutput;
    hasRefinancingCredits: boolean;
    initialConsent: StartLoanApplicationInput;
    cpiAdditionalQuestions: Option<CPIAdditionalQuestions>;
    needsAdditionalIncome: Option<boolean>;
    hasAdditionalIncome: Option<boolean>;
    rework: Rework;
  };
}

export function expensesAndAdditionalIncome(
  loanOffer: LoanOffer,
  personalDataOptions: PersonalDataFromOutput,
  hasRefinancingCredits: boolean,
  initialConsent: StartLoanApplicationInput,
  cpiAdditionalQuestions: Option<CPIAdditionalQuestions>,
  needsAdditionalIncome: Option<boolean>,
  hasAdditionalIncome: Option<boolean>,
  rework: Rework
): ExpensesAndAdditionalIncomeState {
  return {
    type: "ExpensesAndAdditionalIncome",
    payload: {
      loanOffer,
      hasRefinancingCredits,
      personalDataOptions,
      initialConsent,
      cpiAdditionalQuestions,
      needsAdditionalIncome,
      hasAdditionalIncome,
      rework,
    },
  };
}

interface SaveCustomerOfferSuspenseState {
  type: "SaveCustomerOfferSuspense";
  payload: {
    offerType: OfferType;
    loanOffer: LoanOffer;
    personalDataOptions: PersonalDataFromOutput;
    hasRefinancingCredits: boolean;
    initialConsent: StartLoanApplicationInput;
    cpiAdditionalQuestions: Option<CPIAdditionalQuestions>;
    rework: Rework;
  };
}

export function saveCustomerOfferSuspense(
  offerType: OfferType,
  loanOffer: LoanOffer,
  personalDataOptions: PersonalDataFromOutput,
  hasRefinancingCredits: boolean,
  initialConsent: StartLoanApplicationInput,
  cpiAdditionalQuestions: Option<CPIAdditionalQuestions>,
  rework: Rework
): SaveCustomerOfferSuspenseState {
  return {
    type: "SaveCustomerOfferSuspense",
    payload: {
      offerType,
      loanOffer,
      personalDataOptions,
      hasRefinancingCredits,
      initialConsent,
      cpiAdditionalQuestions,
      rework,
    },
  };
}

interface OfferListState {
  type: "OfferList";
  payload: {
    offerType: OfferType;
    loanOffer: LoanOffer;
    personalDataOptions: PersonalDataFromOutput;
    hasRefinancingCredits: boolean;
    initialConsent: StartLoanApplicationInput;
    cpiAdditionalQuestions: Option<CPIAdditionalQuestions>;
    rework: Rework;
  };
}

export function offerList(
  offerType: OfferType,
  loanOffer: LoanOffer,
  personalDataOptions: PersonalDataFromOutput,
  hasRefinancingCredits: boolean,
  initialConsent: StartLoanApplicationInput,
  cpiAdditionalQuestions: Option<CPIAdditionalQuestions>,
  rework: Rework
): OfferListState {
  return {
    type: "OfferList",
    payload: {
      offerType,
      loanOffer,
      personalDataOptions,
      hasRefinancingCredits,
      initialConsent,
      cpiAdditionalQuestions,
      rework,
    },
  };
}

interface CheckExpensesOffersState {
  type: "CheckExpensesOffers";
  payload: {
    loanOffer: LoanOffer;
    personalDataOptions: PersonalDataFromOutput;
    hasRefinancingCredits: boolean;
    initialConsent: StartLoanApplicationInput;
    cpiAdditionalQuestions: Option<CPIAdditionalQuestions>;
    rework: Rework;
  };
}

export function checkExpensesOffers(
  loanOffer: LoanOffer,
  personalDataOptions: PersonalDataFromOutput,
  hasRefinancingCredits: boolean,
  initialConsent: StartLoanApplicationInput,
  cpiAdditionalQuestions: Option<CPIAdditionalQuestions>,
  rework: Rework
): CheckExpensesOffersState {
  return {
    type: "CheckExpensesOffers",
    payload: {
      loanOffer,
      personalDataOptions,
      hasRefinancingCredits,
      initialConsent,
      cpiAdditionalQuestions,
      rework,
    },
  };
}

interface SendAdditionalIncomeState {
  type: "SendAdditionalIncome";
  payload: {
    loanOffer: LoanOffer;
    personalDataOptions: PersonalDataFromOutput;
    hasRefinancingCredits: boolean;
    initialConsent: StartLoanApplicationInput;
    cpiAdditionalQuestions: Option<CPIAdditionalQuestions>;
    rework: Rework;
  };
}

export function sendAdditionalIncome(
  loanOffer: LoanOffer,
  personalDataOptions: PersonalDataFromOutput,
  hasRefinancingCredits: boolean,
  initialConsent: StartLoanApplicationInput,
  cpiAdditionalQuestions: Option<CPIAdditionalQuestions>,
  rework: Rework
): SendAdditionalIncomeState {
  return {
    type: "SendAdditionalIncome",
    payload: {
      loanOffer,
      personalDataOptions,
      hasRefinancingCredits,
      initialConsent,
      cpiAdditionalQuestions,
      rework,
    },
  };
}

interface OfferReviewState {
  type: "OfferReview";
  payload: {
    offerType: OfferType;
    loanOffer: LoanOffer;
    selectedOffer: GenericLoanResponseOutput;
    personalDataOptions: PersonalDataFromOutput;
    hasRefinancingCredits: boolean;
    initialConsent: StartLoanApplicationInput;
    cpiAdditionalQuestions: Option<CPIAdditionalQuestions>;
    rework: Rework;
  };
}

export function offerReview(
  offerType: OfferType,
  loanOffer: LoanOffer,
  selectedOffer: GenericLoanResponseOutput,
  personalDataOptions: PersonalDataFromOutput,
  hasRefinancingCredits: boolean,
  initialConsent: StartLoanApplicationInput,
  cpiAdditionalQuestions: Option<CPIAdditionalQuestions>,
  rework: Rework
): OfferReviewState {
  return {
    type: "OfferReview",
    payload: {
      offerType,
      loanOffer,
      selectedOffer,
      personalDataOptions,
      hasRefinancingCredits,
      initialConsent,
      cpiAdditionalQuestions,
      rework,
    },
  };
}

interface OfferReviewPushState {
  type: "OfferReviewPush";
  payload: {
    offerType: OfferType;
    loanOffer: LoanOffer;
    selectedOffer: GenericLoanResponseOutput;
    personalDataOptions: PersonalDataFromOutput;
    hasRefinancingCredits: boolean;
    initialConsent: StartLoanApplicationInput;
    cpiAdditionalQuestions: Option<CPIAdditionalQuestions>;
    rework: Rework;
    disbursementAccount: Option<NonEmptyString>;
  };
}

export function offerReviewPush(
  offerType: OfferType,
  loanOffer: LoanOffer,
  selectedOffer: GenericLoanResponseOutput,
  personalDataOptions: PersonalDataFromOutput,
  hasRefinancingCredits: boolean,
  initialConsent: StartLoanApplicationInput,
  cpiAdditionalQuestions: Option<CPIAdditionalQuestions>,
  rework: Rework,
  disbursementAccount: Option<NonEmptyString>
): OfferReviewPushState {
  return {
    type: "OfferReviewPush",
    payload: {
      offerType,
      loanOffer,
      selectedOffer,
      personalDataOptions,
      hasRefinancingCredits,
      initialConsent,
      cpiAdditionalQuestions,
      rework,
      disbursementAccount,
    },
  };
}

interface PackageSelectionState {
  type: "PackageSelection";
  payload: {
    offerType: OfferType;
    loanOffer: LoanOffer;
    selectedOffer: GenericLoanResponseOutput;
    personalDataOptions: PersonalDataFromOutput;
    hasRefinancingCredits: boolean;
    initialConsent: StartLoanApplicationInput;
    cpiAdditionalQuestions: Option<CPIAdditionalQuestions>;
    rework: Rework;
  };
}

export function packageSelection(
  offerType: OfferType,
  loanOffer: LoanOffer,
  selectedOffer: GenericLoanResponseOutput,
  personalDataOptions: PersonalDataFromOutput,
  hasRefinancingCredits: boolean,
  initialConsent: StartLoanApplicationInput,
  cpiAdditionalQuestions: Option<CPIAdditionalQuestions>,
  rework: Rework
): PackageSelectionState {
  return {
    type: "PackageSelection",
    payload: {
      offerType,
      loanOffer,
      selectedOffer,
      personalDataOptions,
      hasRefinancingCredits,
      initialConsent,
      cpiAdditionalQuestions,
      rework,
    },
  };
}

interface VirtualCardState {
  type: "VirtualCard";
  payload: {
    offerType: OfferType;
    loanOffer: LoanOffer;
    selectedOffer: GenericLoanResponseOutput;
    personalDataOptions: PersonalDataFromOutput;
    hasRefinancingCredits: boolean;
    initialConsent: StartLoanApplicationInput;
    cpiAdditionalQuestions: Option<CPIAdditionalQuestions>;
    rework: Rework;
  };
}

export function virtualCard(
  offerType: OfferType,
  loanOffer: LoanOffer,
  selectedOffer: GenericLoanResponseOutput,
  personalDataOptions: PersonalDataFromOutput,
  hasRefinancingCredits: boolean,
  initialConsent: StartLoanApplicationInput,
  cpiAdditionalQuestions: Option<CPIAdditionalQuestions>,
  rework: Rework
): VirtualCardState {
  return {
    type: "VirtualCard",
    payload: {
      offerType,
      loanOffer,
      selectedOffer,
      personalDataOptions,
      hasRefinancingCredits,
      initialConsent,
      cpiAdditionalQuestions,
      rework,
    },
  };
}

interface AccountsForRefinancingState {
  type: "AccountsForRefinancing";
  payload: {
    offerType: OfferType;
    loanOffer: LoanOffer;
    selectedOffer: GenericLoanResponseOutput;
    personalDataOptions: PersonalDataFromOutput;
    hasRefinancingCredits: boolean;
    initialConsent: StartLoanApplicationInput;
    cpiAdditionalQuestions: Option<CPIAdditionalQuestions>;
    rework: Rework;
    showVirtualCard?: boolean;
  };
}

export function accountsForRefinancing(
  offerType: OfferType,
  loanOffer: LoanOffer,
  selectedOffer: GenericLoanResponseOutput,
  personalDataOptions: PersonalDataFromOutput,
  hasRefinancingCredits: boolean,
  initialConsent: StartLoanApplicationInput,
  cpiAdditionalQuestions: Option<CPIAdditionalQuestions>,
  rework: Rework,
  showVirtualCard?: boolean
): AccountsForRefinancingState {
  return {
    type: "AccountsForRefinancing",
    payload: {
      offerType,
      loanOffer,
      selectedOffer,
      personalDataOptions,
      hasRefinancingCredits,
      initialConsent,
      cpiAdditionalQuestions,
      rework,
      showVirtualCard,
    },
  };
}

interface KYCState {
  type: "KYC";
  payload: {
    offerType: OfferType;
    loanOffer: LoanOffer;
    selectedOffer: GenericLoanResponseOutput;
    personalDataOptions: PersonalDataFromOutput;
    hasRefinancingCredits: boolean;
    initialConsent: StartLoanApplicationInput;
    cpiAdditionalQuestions: Option<CPIAdditionalQuestions>;
    transactionsInfo: Option<TransactionsInfo>;
    isKycNeeded: boolean;
    rework: Rework;
    showVirtualCard?: boolean;
  };
}

export function kyc(
  offerType: OfferType,
  loanOffer: LoanOffer,
  selectedOffer: GenericLoanResponseOutput,
  personalDataOptions: PersonalDataFromOutput,
  hasRefinancingCredits: boolean,
  cpiAdditionalQuestions: Option<CPIAdditionalQuestions>,
  initialConsent: StartLoanApplicationInput,
  transactionsInfo: Option<TransactionsInfo>,
  rework: Rework,
  showVirtualCard?: boolean
): KYCState {
  return {
    type: "KYC",
    payload: {
      offerType,
      loanOffer,
      selectedOffer,
      hasRefinancingCredits,
      personalDataOptions,
      initialConsent,
      cpiAdditionalQuestions,
      transactionsInfo,
      isKycNeeded: true,
      rework,
      showVirtualCard,
    },
  };
}

interface ReloadSuspenseState {
  type: "ReloadSuspense";
  payload: {
    offerType: OfferType;
    loanOffer: LoanOffer;
    selectedOffer: GenericLoanResponseOutput;
    personalDataOptions: PersonalDataFromOutput;
    hasRefinancingCredits: boolean;
    initialConsent: StartLoanApplicationInput;
    cpiAdditionalQuestions: Option<CPIAdditionalQuestions>;
    transactionsInfo: Option<TransactionsInfo>;
    isKycNeeded: boolean;
    rework: Rework;
  };
}

export function reloadSuspense(
  offerType: OfferType,
  loanOffer: LoanOffer,
  selectedOffer: GenericLoanResponseOutput,
  personalDataOptions: PersonalDataFromOutput,
  hasRefinancingCredits: boolean,
  cpiAdditionalQuestions: Option<CPIAdditionalQuestions>,
  initialConsent: StartLoanApplicationInput,
  transactionsInfo: Option<TransactionsInfo>,
  rework: Rework
): ReloadSuspenseState {
  return {
    type: "ReloadSuspense",
    payload: {
      offerType,
      loanOffer,
      selectedOffer,
      hasRefinancingCredits,
      personalDataOptions,
      initialConsent,
      cpiAdditionalQuestions,
      transactionsInfo,
      isKycNeeded: true,
      rework,
    },
  };
}

interface AfterSignatureState {
  type: "AfterSignature";
  payload: {
    offerType: OfferType;
    loanOffer: LoanOffer;
    selectedOffer: GenericLoanResponseOutput;
    personalDataOptions: PersonalDataFromOutput;
    hasRefinancingCredits: boolean;
    isKycNeeded: boolean;
    initialConsent: StartLoanApplicationInput;
    cpiAdditionalQuestions: Option<CPIAdditionalQuestions>;
    rework: Rework;
    shouldAskCredential: boolean;
    restoredData: Option<RestoreNewestLoanApplicationOutput>;
    lfStatus: Option<LFStatus>;
  };
}

export function afterSignature(
  offerType: OfferType,
  loanOffer: LoanOffer,
  selectedOffer: GenericLoanResponseOutput,
  personalDataOptions: PersonalDataFromOutput,
  hasRefinancingCredits: boolean,
  isKycNeeded: boolean,
  initialConsent: StartLoanApplicationInput,
  cpiAdditionalQuestions: Option<CPIAdditionalQuestions>,
  rework: Rework,
  shouldAskCredential: boolean,
  restoredData: Option<RestoreNewestLoanApplicationOutput>,
  lfStatus: Option<LFStatus>
): AfterSignatureState {
  return {
    type: "AfterSignature",
    payload: {
      offerType,
      loanOffer,
      selectedOffer,
      personalDataOptions,
      hasRefinancingCredits,
      isKycNeeded,
      initialConsent,
      cpiAdditionalQuestions,
      rework,
      shouldAskCredential,
      restoredData,
      lfStatus,
    },
  };
}

interface MicropaymentState {
  type: "Micropayment";
  payload: {
    offerType: OfferType;
    loanOffer: LoanOffer;
    selectedOffer: GenericLoanResponseOutput;
    personalDataOptions: PersonalDataFromOutput;
    hasRefinancingCredits: boolean;
    initialConsent: StartLoanApplicationInput;
    cpiAdditionalQuestions: Option<CPIAdditionalQuestions>;
    rework: Rework;
  };
}

export function micropayment(
  offerType: OfferType,
  loanOffer: LoanOffer,
  selectedOffer: GenericLoanResponseOutput,
  personalDataOptions: PersonalDataFromOutput,
  hasRefinancingCredits: boolean,
  initialConsent: StartLoanApplicationInput,
  cpiAdditionalQuestions: Option<CPIAdditionalQuestions>,
  rework: Rework
): MicropaymentState {
  return {
    type: "Micropayment",
    payload: {
      offerType,
      loanOffer,
      selectedOffer,
      personalDataOptions,
      hasRefinancingCredits,
      initialConsent,
      cpiAdditionalQuestions,
      rework,
    },
  };
}

interface MicropaymentOverviewState {
  type: "MicropaymentOverview";
}

export function micropaymentOverview(): MicropaymentOverviewState {
  return { type: "MicropaymentOverview" };
}

interface FollowUpAndSignatureState {
  type: "FollowUpAndSignature";
  payload: {
    offerType: OfferType;
    loanOffer: LoanOffer;
    selectedOffer: GenericLoanResponseOutput;
    personalDataOptions: PersonalDataFromOutput;
    hasRefinancingCredits: boolean;
    isKycNeeded: boolean;
    initialConsent: StartLoanApplicationInput;
    cpiAdditionalQuestions: Option<CPIAdditionalQuestions>;
    rework: Rework;
    shouldAskCredential: boolean;
    restoredData: Option<RestoreNewestLoanApplicationOutput>;
  };
}

export function followUpAndSignature(
  offerType: OfferType,
  loanOffer: LoanOffer,
  selectedOffer: GenericLoanResponseOutput,
  personalDataOptions: PersonalDataFromOutput,
  hasRefinancingCredits: boolean,
  isKycNeeded: boolean,
  initialConsent: StartLoanApplicationInput,
  cpiAdditionalQuestions: Option<CPIAdditionalQuestions>,
  rework: Rework,
  shouldAskCredential: boolean,
  restoredData: Option<RestoreNewestLoanApplicationOutput>
): FollowUpAndSignatureState {
  return {
    type: "FollowUpAndSignature",
    payload: {
      offerType,
      loanOffer,
      selectedOffer,
      personalDataOptions,
      hasRefinancingCredits,
      isKycNeeded,
      initialConsent,
      cpiAdditionalQuestions,
      rework,
      shouldAskCredential,
      restoredData,
    },
  };
}

export type RejectionReason =
  | "CBRejected"
  | "BonitaRejected"
  | "LFRejected"
  | "RestoredTooEarly"
  | "RejectedWithLead"
  | "ExposureExceeded"
  | "phoneNumberNotVerifiedCF"
  | "phoneNumberMissingCF"
  | "invalidAgeCF"
  | "foreignClientCF"
  | "hasBlockingNotesCF"
  | "highRiskClientCF"
  | "hasNotPasswordForCommunicationCF"
  | "KYC_FIXED_EZY"
  | "KYC_FIXED_EZY_UPDATE_CONTACTS"
  | "UPDATE_CONTACTS"
  | "KYC_ID_FIXED_EZY"
  | "KYC_ID_FIXED_EZY_UPDATE_CONTACTS"
  | "ID_FIXED_EZY"
  | "ID_FIXED_EZY_UPDATE_CONTACTS"
  | "UPDATE_ID"
  | "UPDATE_ID_CONTACTS"
  | "UPDATE_PASSWORD"
  | "UPDATE_ID_CONTACTS_PASSWORD"
  | "UPDATE_ID_PASSWORD"
  | "UPDATE_ID_OTHER_THAN_AUTH_ID"
  | "UPDATE_ID_MISSING_INFORMATION"
  | "USER_VALIDATION_KO_REDIRECT_BRANCH"
  | "privateClient";

interface RejectedState {
  type: "Rejected";
  payload: { rejectionReason: RejectionReason };
}

export function rejected(rejectionReason: RejectionReason): RejectedState {
  return {
    type: "Rejected",
    payload: { rejectionReason },
  };
}

interface SummaryState {
  type: "Summary";
  payload: {
    productType: Option<ApplicationProductType>;
    hasRefinancing: boolean;
    lfStatus: Option<LFStatus>;
  };
}

export function summary(
  productType: Option<ApplicationProductType>,
  hasRefinancing: boolean,
  lfStatus: Option<LFStatus>
): SummaryState {
  return {
    type: "Summary",
    payload: { productType, hasRefinancing, lfStatus },
  };
}

export function error(message: LocaleKey): ErrorState {
  return {
    type: "Error",
    payload: { message },
  };
}

interface ErrorState {
  type: "Error";
  payload: {
    message: LocaleKey;
  };
}

interface ErrorAction {
  type: "Error";
  payload: { message: LocaleKey };
}

export function errorAction(message: LocaleKey): ErrorAction {
  return {
    type: "Error",
    payload: { message },
  };
}

interface UploadDocumentsState {
  type: "UploadDocuments";
  payload: {
    productType: Option<ApplicationProductType>;
    hasRefinancing: boolean;
    lfStatus: Option<LFStatus>;
    loanOffer: LoanOffer;
    rework: Rework;
    requiredDocumentsUpToDate?: boolean;
  };
}

export function uploadDocuments(
  productType: Option<ApplicationProductType>,
  hasRefinancing: boolean,
  lfStatus: Option<LFStatus>,
  loanOffer: LoanOffer,
  rework: Rework,
  requiredDocumentsUpToDate?: boolean
): UploadDocumentsState {
  return {
    type: "UploadDocuments",
    payload: {
      productType,
      hasRefinancing,
      lfStatus,
      loanOffer,
      rework,
      requiredDocumentsUpToDate,
    },
  };
}

interface PersonalDataState {
  type: "PersonalData";
  payload: {
    loanOffer: LoanOffer;
    extractedData: Option<ExtractedDataResult>;
    rework: Rework;
  };
}

export function personalData(
  loanOffer: LoanOffer,
  extractedData: Option<ExtractedDataResult>,
  rework: Rework
): PersonalDataState {
  return {
    type: "PersonalData",
    payload: { loanOffer, extractedData, rework },
  };
}

type RejectableSteps =
  | RestoreNewestLoanApplicationChooseOfferToKycStepsOutput
  | RestoreNewestLoanApplicationMicroPaymentStepsOutput
  | RestoreNewestLoanApplicationPersonalDataStepOutput
  | RestoreNewestLoanApplicationOtherStepsOutput;

export type State =
  | LoadingState
  | LandingState
  | Select3PState
  | PhoneAndEmailVerificationState
  | AddPhoneNumberState
  | UploadIDState
  | CreditChecksState
  | ExistingClientState
  | ExistingApplication3PErrorState
  | RestoreApplicationState
  | ApplicationLockedState
  | CustomerOfferState
  | ExpensesAndAdditionalIncomeState
  | CheckAdditionalIncomesState
  | OfferListState
  | SaveCustomerOfferSuspenseState
  | CheckExpensesOffersState
  | SendAdditionalIncomeState
  | OfferReviewState
  | OfferReviewPushState
  | PackageSelectionState
  | VirtualCardState
  | AccountsForRefinancingState
  | KYCState
  | MicropaymentState
  | MicropaymentOverviewState
  | FollowUpAndSignatureState
  | RejectedState
  | SummaryState
  | UploadDocumentsState
  | PersonalDataState
  | ReloadSuspenseState
  | AfterSignatureState
  | ExistingClientSuspenseState
  | MaxNumberChangesReachedState
  | ErrorState;

export function foldState<T>(
  matches: {
    [k in State["type"]]: Reader<Extract<State, { type: k }>, T>;
  }
): Reader<State, T> {
  return state => matches[state.type](state as any);
}

interface GoBackAction {
  type: "GoBack";
}

export function goBackAction(): GoBackAction {
  return { type: "GoBack" };
}

interface GoToStepAction {
  type: "GoToStep";
  step:
    | "VerifyPhoneAndEmail"
    | "UploadID"
    | "CreditChecks"
    | "CustomerOffer"
    | "ExpensesAndAdditionalIncome"
    | "OfferList"
    | "CheckExpensesOffers"
    | "SendAdditionalIncome"
    | "OfferReview"
    | "OfferReviewPush"
    | "KYC"
    | "PackageSelection"
    | "VirtualCard"
    | "AccountsForRefinancing"
    | "Micropayment"
    | "FollowUpAndSignature"
    | "UploadDocuments"
    | "ReworkNextStep"
    | "ReworkPreviousStep"
    | "PersonalData";
}

export function goToStepAction(step: GoToStepAction["step"]): GoToStepAction {
  return { type: "GoToStep", step };
}

interface LandingAction {
  type: "Landing";
}

export function landingAction(): LandingAction {
  return { type: "Landing" };
}

interface ReloadAction {
  type: "Reload";
}

export function reloadAction(): ReloadAction {
  return { type: "Reload" };
}

interface VerifyPhoneAndEmailAction {
  type: "VerifyPhoneAndEmail";
  payload: {
    loanOffer: Option<LoanOffer>;
  };
}

export function verifyPhoneAndEmailAction(
  loanOffer: Option<LoanOffer>
): VerifyPhoneAndEmailAction {
  return { type: "VerifyPhoneAndEmail", payload: { loanOffer } };
}

interface Select3PAction {
  type: "Select3P";
  payload: {
    loanOffer: LoanOffer;
  };
}

export function select3PAction(loanOffer: LoanOffer): Select3PAction {
  return { type: "Select3P", payload: { loanOffer } };
}

interface UploadIDAction {
  type: "UploadID";
  payload: { loanOffer: LoanOffer };
}

export function uploadIdAction(loanOffer: LoanOffer): UploadIDAction {
  return { type: "UploadID", payload: { loanOffer } };
}

interface ExistingClientSuspenseAction {
  type: "ExistingClientSuspense";
  payload: { loanOffer: Option<LoanOffer>; isApplicationExisting: boolean };
}

export function existingClientSuspenseAction(
  loanOffer: Option<LoanOffer>,
  isApplicationExisting: boolean
): ExistingClientSuspenseAction {
  return {
    type: "ExistingClientSuspense",
    payload: { loanOffer, isApplicationExisting },
  };
}

interface CreditChecksAction {
  type: "CreditChecks";
  payload: {
    loanOffer: LoanOffer;
  };
}

export function creditChecksAction(loanOffer: LoanOffer): CreditChecksAction {
  return {
    type: "CreditChecks",
    payload: { loanOffer },
  };
}

interface ExistingClientAction {
  type: "ExistingClient";
  reason: RefuseExistingClientReason;
}

export function existingClientAction(
  reason: RefuseExistingClientReason
): ExistingClientAction {
  return { type: "ExistingClient", reason };
}

interface ExistingApplication3PErrorAction {
  type: "ExistingApplication3PError";
}

export function existingApplication3PErrorAction(): ExistingApplication3PErrorAction {
  return { type: "ExistingApplication3PError" };
}

interface RestoreApplicationAction {
  type: "RestoreApplication";
  payload: RestoreApplicationPayload;
}

export function restoreApplicationAction(
  payload: RestoreApplicationPayload
): RestoreApplicationAction {
  return {
    type: "RestoreApplication",
    payload,
  };
}

interface ApplicationLockedState {
  type: "ApplicationLocked";
}

export function applicationLocked(): ApplicationLockedState {
  return {
    type: "ApplicationLocked",
  };
}

interface ApplicationLockedAction {
  type: "ApplicationLocked";
}

export function applicationLockedAction(): ApplicationLockedAction {
  return {
    type: "ApplicationLocked",
  };
}

interface CustomerOfferAction {
  type: "CustomerOffer";
  payload: {
    personalDataOptions: PersonalDataFromOutput;
    startLoanApplicationConsent: StartLoanApplicationInput;
  };
}

export function customerOfferAction(
  personalDataOptions: PersonalDataFromOutput,
  startLoanApplicationConsent: StartLoanApplicationInput
): CustomerOfferAction {
  return {
    type: "CustomerOffer",
    payload: { personalDataOptions, startLoanApplicationConsent },
  };
}

interface ExpensesAndAdditionalIncomeAction {
  type: "ExpensesAndAdditionalIncome";
  payload:
    | {
        hasRefinancingCredits: boolean;
        restoredPersonalDataOptions: Option<PersonalDataFromOutput>;
        fromBack: false;
      }
    | {
        needsAdditionalIncome: Option<boolean>;
        hasAdditionalIncome: Option<boolean>;
        fromBack: true;
      };
}

export function expensesAndAdditionalIncomeAction(
  hasRefinancingCredits: boolean
): ExpensesAndAdditionalIncomeAction {
  return {
    type: "ExpensesAndAdditionalIncome",
    payload: {
      hasRefinancingCredits,
      restoredPersonalDataOptions: option.none,
      fromBack: false,
    },
  };
}

export function expensesAndAdditionalIncomeAction2(
  needsAdditionalIncome: Option<boolean>,
  hasAdditionalIncome: Option<boolean>
): ExpensesAndAdditionalIncomeAction {
  return {
    type: "ExpensesAndAdditionalIncome",
    payload: {
      needsAdditionalIncome,
      hasAdditionalIncome,
      fromBack: true,
    },
  };
}

interface ExpensesAndAdditionalIncomeReworkAction {
  type: "ExpensesAndAdditionalIncomeRework";
  payload: {
    startLoanApplicationConsent: StartLoanApplicationInput;
    hasRefinancingCredits: boolean;
    needsAdditionalIncome: Option<boolean>;
    hasAdditionalIncome: Option<boolean>;
    personalDataOptions: PersonalDataFromOutput;
    cpiAdditionalQuestions: Option<CPIAdditionalQuestions>;
  };
}

export function expensesAndAdditionalIncomeReworkAction(
  startLoanApplicationConsent: StartLoanApplicationInput,
  hasRefinancingCredits: boolean,
  needsAdditionalIncome: Option<boolean>,
  hasAdditionalIncome: Option<boolean>,
  personalDataOptions: PersonalDataFromOutput,
  cpiAdditionalQuestions: Option<CPIAdditionalQuestions>
): ExpensesAndAdditionalIncomeReworkAction {
  return {
    type: "ExpensesAndAdditionalIncomeRework",
    payload: {
      startLoanApplicationConsent,
      hasRefinancingCredits,
      needsAdditionalIncome,
      hasAdditionalIncome,
      personalDataOptions,
      cpiAdditionalQuestions,
    },
  };
}

interface SaveCustomerOfferSuspenseAction {
  type: "SaveCustomerOfferSuspense";
  payload: {
    offerType: OfferType;
    restoredPersonalDataOptions: Option<PersonalDataFromOutput>;
  };
}

export function saveCustomerOfferSuspenseAction(
  offerType: OfferType
): SaveCustomerOfferSuspenseAction {
  return {
    type: "SaveCustomerOfferSuspense",
    payload: { offerType, restoredPersonalDataOptions: option.none },
  };
}

interface OfferListAction {
  type: "OfferList";
  payload: {
    offerType: OfferType;
    restoredPersonalDataOptions: Option<PersonalDataFromOutput>;
  };
}

export function offerListAction(offerType: OfferType): OfferListAction {
  return {
    type: "OfferList",
    payload: { offerType, restoredPersonalDataOptions: option.none },
  };
}

interface OfferListReworkAction {
  type: "OfferList_Rework";
  payload: {
    offerType: OfferType;
    restoredPersonalDataOptions: PersonalDataFromOutput;
    initialConsent: StartLoanApplicationInput;
  };
}

export function offerListReworkAction(
  offerType: OfferType,
  restoredPersonalDataOptions: PersonalDataFromOutput,
  initialConsent: StartLoanApplicationInput
): OfferListReworkAction {
  return {
    type: "OfferList_Rework",
    payload: { offerType, restoredPersonalDataOptions, initialConsent },
  };
}

interface CheckExpensesOffersAction {
  type: "CheckExpensesOffers";
  payload: {
    restoredPersonalDataOptions: Option<PersonalDataFromOutput>;
  };
}

export function checkExpensesOffersAction(): CheckExpensesOffersAction {
  return {
    type: "CheckExpensesOffers",
    payload: { restoredPersonalDataOptions: option.none },
  };
}

interface SendAdditionalIncomeAction {
  type: "SendAdditionalIncome";
  payload: {
    restoredPersonalDataOptions: Option<PersonalDataFromOutput>;
  };
}

export function sendAdditionalIncomeAction(): SendAdditionalIncomeAction {
  return {
    type: "SendAdditionalIncome",
    payload: { restoredPersonalDataOptions: option.none },
  };
}

interface OfferReviewAction {
  type: "OfferReview";
  payload: {
    selectedOffer: GenericLoanResponseOutput;
    restoredPersonalDataOptions: Option<PersonalDataFromOutput>;
  };
}

export function offerReviewAction(
  selectedOffer: GenericLoanResponseOutput
): OfferReviewAction {
  return {
    type: "OfferReview",
    payload: { selectedOffer, restoredPersonalDataOptions: option.none },
  };
}

interface OfferReviewPushAction {
  type: "OfferReviewPush";
  payload: {
    selectedOffer: GenericLoanResponseOutput;
    restoredPersonalDataOptions: Option<PersonalDataFromOutput>;
  };
}

export function offerReviewPushAction(
  selectedOffer: GenericLoanResponseOutput
): OfferReviewPushAction {
  return {
    type: "OfferReviewPush",
    payload: { selectedOffer, restoredPersonalDataOptions: option.none },
  };
}

interface PackageSelectionAction {
  type: "PackageSelection";
  payload: {
    loanOffer: LoanOffer;
    chosenCard: Option<UKontoPackageType>;
  };
}

export function packageSelectionAction(
  loanOffer: LoanOffer,
  chosenCard: Option<UKontoPackageType>
): PackageSelectionAction {
  return {
    type: "PackageSelection",
    payload: {
      loanOffer,
      chosenCard,
    },
  };
}

interface VirtualCardAction {
  type: "VirtualCard";
  payload: {
    loanOffer: LoanOffer;
  };
}

export function virtualCardAction(loanOffer: LoanOffer): VirtualCardAction {
  return {
    type: "VirtualCard",
    payload: {
      loanOffer,
    },
  };
}

interface AccountsForRefinancingAction {
  type: "AccountsForRefinancing";
  payload: {
    restoredPersonalDataOptions: Option<PersonalDataFromOutput>;
    hasRefinancingCredits: boolean;
  };
}

export function accountsForRefinancingAction(
  hasRefinancingCredits: boolean
): AccountsForRefinancingAction {
  return {
    type: "AccountsForRefinancing",
    payload: {
      restoredPersonalDataOptions: option.none,
      hasRefinancingCredits,
    },
  };
}

interface KYCAction {
  type: "KYC";
  payload: {
    restoredPersonalDataOptions: Option<PersonalDataFromOutput>;
    hasRefinancingCredits: boolean;
  };
}

export function kycAction(hasRefinancingCredits: boolean): KYCAction {
  return {
    type: "KYC",
    payload: {
      restoredPersonalDataOptions: option.none,
      hasRefinancingCredits,
    },
  };
}

interface MicropaymentAction {
  type: "Micropayment";
  payload: {
    restoredPersonalDataOptions: Option<PersonalDataFromOutput>;
  };
}

export function micropaymentAction(): MicropaymentAction {
  return {
    type: "Micropayment",
    payload: { restoredPersonalDataOptions: option.none },
  };
}

interface MicropaymentOverivewAction {
  type: "MicropaymentOverview";
}

export function micropaymentOverviewAction(): MicropaymentOverivewAction {
  return { type: "MicropaymentOverview" };
}

interface FollowUpAndSignatureAction {
  type: "FollowUpAndSignature";
  payload: {
    restoredPersonalDataOptions: Option<PersonalDataFromOutput>;
    hasRefinancingCredits: boolean;
    isKycNeeded: boolean;
    askForCredentials?: boolean;
  };
}

interface ReloadSuspenseAction {
  type: "ReloadSuspense";
}

interface AfterSignatureAction {
  type: "AfterSignature";
  payload: {
    lfStatus: Option<LFStatus>;
  };
}

export function afterSignatureAction(
  lfStatus: Option<LFStatus>
): AfterSignatureAction {
  return {
    type: "AfterSignature",
    payload: {
      lfStatus,
    },
  };
}

export function followUpAndSignatureAction(
  hasRefinancingCredits: boolean,
  isKycNeeded: boolean,
  askForCredentials?: boolean
): FollowUpAndSignatureAction {
  return {
    type: "FollowUpAndSignature",
    payload: {
      restoredPersonalDataOptions: option.none,
      hasRefinancingCredits,
      isKycNeeded,
      askForCredentials,
    },
  };
}

export function restoreFollowUpAndSignatureAction(
  restoredPersonalDataOptions: PersonalDataFromOutput,
  hasRefinancingCredits: boolean,
  isKycNeeded: boolean
): FollowUpAndSignatureAction {
  return {
    type: "FollowUpAndSignature",
    payload: {
      restoredPersonalDataOptions: option.some(restoredPersonalDataOptions),
      hasRefinancingCredits,
      isKycNeeded,
    },
  };
}

interface RejectAction {
  type: "Reject";
  payload: { rejectionReason: RejectionReason };
}

export function rejectAction(rejectionReason: RejectionReason): RejectAction {
  return { type: "Reject", payload: { rejectionReason } };
}

export interface SummaryAction {
  type: "Summary";
  payload: {
    productType: Option<ApplicationProductType>;
    hasRefinancing: boolean;
    lfStatus: Option<LFStatus>;
  };
}

export function summaryAction(
  productType: Option<ApplicationProductType>,
  hasRefinancing: boolean,
  lfStatus: Option<LFStatus>
): SummaryAction {
  return {
    type: "Summary",
    payload: { productType, hasRefinancing, lfStatus },
  };
}

export interface MaxNumberChangesReachedAction {
  type: "MaxNumberChangesReached";
}

export function maxNumberChangesReachedAction(): MaxNumberChangesReachedAction {
  return {
    type: "MaxNumberChangesReached",
  };
}

interface UploadDocumentsAction {
  type: "UploadDocuments";
  payload: { lfStatus: Option<LFStatus> };
}

export function uploadDocumentsAction(
  lfStatus: Option<LFStatus>
): UploadDocumentsAction {
  return { type: "UploadDocuments", payload: { lfStatus } };
}

interface PersonalDataAction {
  type: "PersonalData";
}

export function personalDataAction(): PersonalDataAction {
  return { type: "PersonalData" };
}

interface RestoreStepAction {
  type: "RestoreStep";
  payload: {
    restoredData: RestoreNewestLoanApplicationOutput;
    isKycNeeded: boolean;
    rework: Rework;
    applicationStatus: Option<ApplicationStatus>;
    signatureStatus: SignatureStatus;
  };
}

export function restoreStepAction(
  restoredData: RestoreNewestLoanApplicationOutput,
  isKycNeeded: boolean,
  rework: Rework,
  applicationStatus: Option<ApplicationStatus>,
  signatureStatus: SignatureStatus
): RestoreStepAction {
  return {
    type: "RestoreStep",
    payload: {
      restoredData,
      isKycNeeded,
      rework,
      applicationStatus,
      signatureStatus,
    },
  };
}

export type Action =
  | ReloadAction
  | LandingAction
  | GoBackAction
  | GoToStepAction
  | VerifyPhoneAndEmailAction
  | Select3PAction
  | UploadIDAction
  | CreditChecksAction
  | ExistingClientAction
  | ExistingApplication3PErrorAction
  | RestoreApplicationAction
  | ApplicationLockedAction
  | CustomerOfferAction
  | ExpensesAndAdditionalIncomeAction
  | ExpensesAndAdditionalIncomeReworkAction
  | OfferListAction
  | OfferListReworkAction
  | SaveCustomerOfferSuspenseAction
  | CheckExpensesOffersAction
  | SendAdditionalIncomeAction
  | OfferReviewAction
  | OfferReviewPushAction
  | PackageSelectionAction
  | VirtualCardAction
  | AccountsForRefinancingAction
  | KYCAction
  | MicropaymentAction
  | MicropaymentOverivewAction
  | FollowUpAndSignatureAction
  | RejectAction
  | SummaryAction
  | UploadDocumentsAction
  | RestoreStepAction
  | ReloadSuspenseAction
  | AfterSignatureAction
  | ExistingClientSuspenseAction
  | MaxNumberChangesReachedAction
  | ErrorAction;

export const emptyRework: Rework = {
  needContractsUpdate: false,
  reworkSteps: option.none,
  oldValues: option.none,
  newValues: option.none,
  reworkAll: false,
};

export const emptyParallelFlowParams: ParallelFlowParameters = {
  hasCAFlowInProgress: false,
  hasCFFlowSentToBO: false,
  hasCAFlowSentToBO: false,
  date: option.none,
  existingApplicationId: option.none,
};

export function reducer(state: State, action: Action): State {
  if (action.type === "Error" && state.type !== "Error") {
    return error(action.payload.message);
  }

  switch (state.type) {
    case "Error":
      return state;
    case "Loading":
      switch (action.type) {
        case "Landing":
          return landing();
        case "VerifyPhoneAndEmail":
          return verifyPhoneAndEmail(action.payload.loanOffer);
        case "Select3P":
          return select3P(action.payload.loanOffer);
        case "RestoreApplication":
          return restoreApplication(action.payload);
        case "CreditChecks":
          return creditChecks(
            action.payload.loanOffer,
            option.none,
            false,
            emptyRework,
            false,
            option.none
          );
        case "RestoreStep":
          return restoreState(
            action.payload.restoredData,
            action.payload.isKycNeeded,
            action.payload.rework,
            action.payload.applicationStatus,
            action.payload.signatureStatus
          );
        case "ApplicationLocked":
          return applicationLocked();
        case "ExistingClientSuspense":
          return existingClientSuspense(
            action.payload.loanOffer,
            action.payload.isApplicationExisting
          );
        case "Reject":
          return rejected(action.payload.rejectionReason);
        default:
          return state;
      }
    case "Landing":
      switch (action.type) {
        case "VerifyPhoneAndEmail":
          return verifyPhoneAndEmail(action.payload.loanOffer);
        case "Select3P":
          return select3P(action.payload.loanOffer);
        case "CreditChecks":
          return creditChecks(
            action.payload.loanOffer,
            option.none,
            false,
            emptyRework,
            false,
            option.none
          );
        case "RestoreApplication":
          return restoreApplication(action.payload);
        case "ExistingClientSuspense":
          return existingClientSuspense(
            action.payload.loanOffer,
            action.payload.isApplicationExisting
          );
        case "ApplicationLocked":
          return applicationLocked();
        default:
          return state;
      }
    case "Select3P":
      switch (action.type) {
        case "GoBack":
          return landing();
        case "VerifyPhoneAndEmail":
          return verifyPhoneAndEmail(action.payload.loanOffer);
        case "CreditChecks":
          return creditChecks(
            action.payload.loanOffer,
            option.none,
            false,
            emptyRework,
            false,
            option.none
          );
        default:
          return state;
      }
    case "PhoneAndEmailVerification":
      switch (action.type) {
        case "GoBack":
          return landing();
        case "UploadID":
          return uploadId(action.payload.loanOffer);
        case "MaxNumberChangesReached":
          return maxNumberChangesReached();
        case "VerifyPhoneAndEmail":
          return verifyPhoneAndEmail(action.payload.loanOffer);
        default:
          return state;
      }
    case "AddPhoneNumber":
      switch (action.type) {
        case "Reload":
          return loading();
        case "MaxNumberChangesReached":
          return maxNumberChangesReached();
        default:
          return state;
      }
    case "UploadID":
      switch (action.type) {
        case "GoBack":
          return verifyPhoneAndEmail(option.some(state.payload.loanOffer));
        case "GoToStep":
          switch (action.step) {
            case "VerifyPhoneAndEmail":
              return verifyPhoneAndEmail(option.some(state.payload.loanOffer));
            default:
              return state;
          }
        case "ExistingClient":
          return existingClient(action.reason);
        case "ExistingApplication3PError":
          return existingApplication3PError();
        case "CreditChecks":
          return creditChecks(
            state.payload.loanOffer,
            option.none,
            false,
            emptyRework,
            false,
            option.none
          );
        case "RestoreApplication":
          return restoreApplication(action.payload);
        case "RestoreStep":
          return restoreState(
            action.payload.restoredData,
            action.payload.isKycNeeded,
            action.payload.rework,
            action.payload.applicationStatus,
            action.payload.signatureStatus
          );
        case "ApplicationLocked":
          return applicationLocked();
        case "ExistingClientSuspense":
          return existingClientSuspense(
            action.payload.loanOffer,
            action.payload.isApplicationExisting
          );
        default:
          return state;
      }
    case "CreditChecks":
      switch (action.type) {
        case "GoToStep":
          switch (action.step) {
            case "ReworkNextStep":
              return navigateReworkStep(state, "next");
            default:
              return state;
          }
        case "CustomerOffer":
          return customerOffer(
            state.payload.loanOffer,
            action.payload.personalDataOptions,
            action.payload.startLoanApplicationConsent,
            option.none,
            state.payload.rework
          );
        case "ExpensesAndAdditionalIncomeRework":
          return expensesAndAdditionalIncome(
            state.payload.loanOffer,
            action.payload.personalDataOptions,
            action.payload.hasRefinancingCredits,
            action.payload.startLoanApplicationConsent,
            action.payload.cpiAdditionalQuestions,
            action.payload.needsAdditionalIncome,
            action.payload.hasAdditionalIncome,
            state.payload.rework
          );
        case "OfferList_Rework":
          return offerList(
            action.payload.offerType,
            state.payload.loanOffer,
            action.payload.restoredPersonalDataOptions,
            state.payload.hasRefinancingCredits,
            action.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            state.payload.rework
          );
        case "Reject":
          return rejected(action.payload.rejectionReason);
        default:
          return state;
      }
    case "ExistingClient":
      return state;
    case "ExistingApplication3PError":
      return state;
    case "CustomerOffer":
      switch (action.type) {
        case "GoBack":
          return creditChecks(
            state.payload.loanOffer,
            option.some(state.payload.initialConsent),
            true,
            state.payload.rework,
            false,
            option.none
          );
        case "GoToStep":
          switch (action.step) {
            case "CreditChecks":
              return creditChecks(
                state.payload.loanOffer,
                option.some(state.payload.initialConsent),
                true,
                state.payload.rework,
                false,
                option.none
              );
            default:
              return state;
          }
        case "ExpensesAndAdditionalIncome":
          return expensesAndAdditionalIncome(
            state.payload.loanOffer,
            state.payload.personalDataOptions,
            action.payload.fromBack
              ? false
              : action.payload.hasRefinancingCredits,
            state.payload.initialConsent,
            option.none,
            option.none,
            option.none,
            state.payload.rework
          );
        case "Reject":
          return rejected(action.payload.rejectionReason);
        default:
          return state;
      }
    case "RestoreApplication":
      switch (action.type) {
        case "CreditChecks":
          if (!state.payload.isErrorFetchingRestoredData) {
            return creditChecks(
              action.payload.loanOffer,
              option.none,
              false,
              state.payload.rework,
              false,
              option.none
            );
          }
          return state;
        case "Landing":
          return landing();
        case "VerifyPhoneAndEmail":
          return verifyPhoneAndEmail(action.payload.loanOffer);
        case "Reject":
          return rejected(action.payload.rejectionReason);
        case "RestoreStep":
          if (!state.payload.isErrorFetchingRestoredData) {
            return restoreState(
              action.payload.restoredData,
              action.payload.isKycNeeded,
              action.payload.rework === emptyRework
                ? state.payload.rework
                : action.payload.rework,
              action.payload.applicationStatus,
              action.payload.signatureStatus
            );
          }
          return state;
        default:
          return state;
      }
    case "ApplicationLocked":
      return state;
    case "ExpensesAndAdditionalIncome":
      switch (action.type) {
        case "GoBack":
          return customerOffer(
            state.payload.loanOffer,
            state.payload.personalDataOptions,
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            state.payload.rework
          );
        case "GoToStep":
          switch (action.step) {
            case "CreditChecks":
              return creditChecks(
                state.payload.loanOffer,
                option.some(state.payload.initialConsent),
                true,
                state.payload.rework,
                false,
                option.none
              );
            case "CustomerOffer":
              return customerOffer(
                state.payload.loanOffer,
                state.payload.personalDataOptions,
                state.payload.initialConsent,
                state.payload.cpiAdditionalQuestions,
                state.payload.rework
              );
            case "ReworkPreviousStep":
              return navigateReworkStep(state, "prev");
            case "ReworkNextStep":
              return navigateReworkStep(state, "next");
            default:
              return state;
          }
        case "OfferList":
          return offerList(
            action.payload.offerType,
            state.payload.loanOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            state.payload.rework
          );
        case "SaveCustomerOfferSuspense":
          return saveCustomerOfferSuspense(
            action.payload.offerType,
            state.payload.loanOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            state.payload.rework
          );
        case "CheckExpensesOffers":
          return checkExpensesOffers(
            state.payload.loanOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            state.payload.rework
          );
        case "Reject":
          return rejected(action.payload.rejectionReason);
        default:
          return state;
      }
    case "CheckAdditionalIncomes":
      switch (action.type) {
        case "ExpensesAndAdditionalIncome":
          return expensesAndAdditionalIncome(
            state.payload.loanOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            action.payload.fromBack
              ? action.payload.needsAdditionalIncome
              : state.payload.needsAdditionalIncome,
            action.payload.fromBack
              ? action.payload.hasAdditionalIncome
              : state.payload.hasAdditionalIncome,
            state.payload.rework
          );
        default:
          return state;
      }

    case "OfferList":
      switch (action.type) {
        case "GoBack":
          return checkAdditionalIncomes(
            state.payload.loanOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            option.none,
            option.none,
            state.payload.rework
          );
        case "GoToStep":
          switch (action.step) {
            case "CreditChecks":
              return creditChecks(
                state.payload.loanOffer,
                option.some(state.payload.initialConsent),
                true,
                state.payload.rework,
                false,
                option.none
              );
            case "CustomerOffer":
              return customerOffer(
                state.payload.loanOffer,
                state.payload.personalDataOptions,
                state.payload.initialConsent,
                state.payload.cpiAdditionalQuestions,
                state.payload.rework
              );
            case "ExpensesAndAdditionalIncome":
              return expensesAndAdditionalIncome(
                state.payload.loanOffer,
                state.payload.personalDataOptions,
                state.payload.hasRefinancingCredits,
                state.payload.initialConsent,
                state.payload.cpiAdditionalQuestions,
                option.none,
                option.none,
                state.payload.rework
              );
            default:
              return state;
          }
        case "OfferReview":
          return offerReview(
            state.payload.offerType,
            state.payload.loanOffer,
            action.payload.selectedOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            state.payload.rework
          );
        case "Reject":
          return rejected(action.payload.rejectionReason);
        default:
          return state;
      }
    case "SaveCustomerOfferSuspense":
      switch (action.type) {
        case "GoBack": // TODO check if still needed
          return checkAdditionalIncomes(
            state.payload.loanOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            option.none,
            option.none,
            state.payload.rework
          );
        case "OfferReview":
          return offerReview(
            state.payload.offerType,
            state.payload.loanOffer,
            action.payload.selectedOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            state.payload.rework
          );
        case "Reject":
          return rejected(action.payload.rejectionReason);
        default:
          return state;
      }
    case "CheckExpensesOffers":
      switch (action.type) {
        case "GoBack":
          return checkAdditionalIncomes(
            state.payload.loanOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            option.none,
            option.none,
            state.payload.rework
          );
        case "SendAdditionalIncome":
          return sendAdditionalIncome(
            state.payload.loanOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            state.payload.rework
          );
        case "Reject":
          return rejected(action.payload.rejectionReason);
        default:
          return state;
      }
    case "SendAdditionalIncome":
      switch (action.type) {
        case "GoBack":
          return checkAdditionalIncomes(
            state.payload.loanOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            option.none,
            option.none,
            state.payload.rework
          );
        case "OfferList":
          return offerList(
            action.payload.offerType,
            state.payload.loanOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            state.payload.rework
          );
        case "SaveCustomerOfferSuspense":
          return saveCustomerOfferSuspense(
            action.payload.offerType,
            state.payload.loanOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            state.payload.rework
          );
        case "Reject":
          return rejected(action.payload.rejectionReason);
        default:
          return state;
      }
    case "OfferReview":
      switch (action.type) {
        case "GoBack":
          return offerList(
            state.payload.offerType,
            state.payload.loanOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            state.payload.rework
          );
        case "GoToStep":
          switch (action.step) {
            case "CreditChecks":
              return creditChecks(
                state.payload.loanOffer,
                option.some(state.payload.initialConsent),
                true,
                state.payload.rework,
                false,
                option.none
              );
            case "CustomerOffer":
              return customerOffer(
                state.payload.loanOffer,
                state.payload.personalDataOptions,
                state.payload.initialConsent,
                state.payload.cpiAdditionalQuestions,
                state.payload.rework
              );
            case "ExpensesAndAdditionalIncome":
              return expensesAndAdditionalIncome(
                state.payload.loanOffer,
                state.payload.personalDataOptions,
                state.payload.hasRefinancingCredits,
                state.payload.initialConsent,
                state.payload.cpiAdditionalQuestions,
                option.none,
                option.none,
                state.payload.rework
              );
            case "OfferList":
              return offerList(
                state.payload.offerType,
                state.payload.loanOffer,
                state.payload.personalDataOptions,
                state.payload.hasRefinancingCredits,
                state.payload.initialConsent,
                state.payload.cpiAdditionalQuestions,
                state.payload.rework
              );
            case "UploadDocuments":
              return uploadDocuments(
                state.payload.selectedOffer.productType,
                state.payload.hasRefinancingCredits,
                option.none,
                state.payload.loanOffer,
                state.payload.rework,
                true
              );
            case "ReworkPreviousStep":
              return navigateReworkStep(state, "prev");
            case "ReworkNextStep":
              return navigateReworkStep(state, "next");
            default:
              return state;
          }
        case "PackageSelection":
          return packageSelection(
            state.payload.offerType,
            state.payload.loanOffer,
            state.payload.selectedOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            state.payload.rework
          );
        case "VirtualCard":
          return virtualCard(
            state.payload.offerType,
            state.payload.loanOffer,
            state.payload.selectedOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            state.payload.rework
          );
        case "AccountsForRefinancing":
          return accountsForRefinancing(
            state.payload.offerType,
            state.payload.loanOffer,
            state.payload.selectedOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            state.payload.rework
          );
        case "KYC":
          return kyc(
            state.payload.offerType,
            state.payload.loanOffer,
            state.payload.selectedOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.cpiAdditionalQuestions,
            state.payload.initialConsent,
            option.none,
            state.payload.rework
          );
        case "Reject":
          return rejected(action.payload.rejectionReason);
        case "UploadDocuments":
          return uploadDocuments(
            state.payload.selectedOffer.productType,
            state.payload.hasRefinancingCredits,
            action.payload.lfStatus,
            state.payload.loanOffer,
            state.payload.rework,
            true
          );
        case "FollowUpAndSignature":
          return followUpAndSignature(
            state.payload.offerType,
            state.payload.loanOffer,
            state.payload.selectedOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            action.payload.isKycNeeded,
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            state.payload.rework,
            action.payload.askForCredentials === undefined
              ? true
              : action.payload.askForCredentials,
            option.none
          );
        default:
          return state;
      }
    case "OfferReviewPush":
      switch (action.type) {
        case "Reject":
          return rejected(action.payload.rejectionReason);
        case "FollowUpAndSignature":
          return followUpAndSignature(
            state.payload.offerType,
            state.payload.loanOffer,
            state.payload.selectedOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            action.payload.isKycNeeded,
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            state.payload.rework,
            true,
            option.none
          );
        default:
          return state;
      }
    case "PackageSelection":
      switch (action.type) {
        case "GoBack":
          return offerReview(
            state.payload.offerType,
            state.payload.loanOffer,
            state.payload.selectedOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            state.payload.rework
          );
        case "GoToStep":
          switch (action.step) {
            case "CreditChecks":
              return creditChecks(
                state.payload.loanOffer,
                option.some(state.payload.initialConsent),
                true,
                state.payload.rework,
                false,
                option.none
              );
            case "CustomerOffer":
              return customerOffer(
                state.payload.loanOffer,
                state.payload.personalDataOptions,
                state.payload.initialConsent,
                state.payload.cpiAdditionalQuestions,
                state.payload.rework
              );
            case "ExpensesAndAdditionalIncome":
              return expensesAndAdditionalIncome(
                state.payload.loanOffer,
                state.payload.personalDataOptions,
                state.payload.hasRefinancingCredits,
                state.payload.initialConsent,
                state.payload.cpiAdditionalQuestions,
                option.none,
                option.none,
                state.payload.rework
              );
            case "OfferList":
              return offerList(
                state.payload.offerType,
                state.payload.loanOffer,
                state.payload.personalDataOptions,
                state.payload.hasRefinancingCredits,
                state.payload.initialConsent,
                state.payload.cpiAdditionalQuestions,
                state.payload.rework
              );
            case "OfferReview":
              return offerReview(
                state.payload.offerType,
                state.payload.loanOffer,
                state.payload.selectedOffer,
                state.payload.personalDataOptions,
                state.payload.hasRefinancingCredits,
                state.payload.initialConsent,
                state.payload.cpiAdditionalQuestions,
                state.payload.rework
              );
            default:
              return state;
          }
        case "VirtualCard":
          return virtualCard(
            state.payload.offerType,
            state.payload.loanOffer,
            state.payload.selectedOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            state.payload.rework
          );
        case "Reject":
          return rejected(action.payload.rejectionReason);
        default:
          return state;
      }
    case "VirtualCard":
      switch (action.type) {
        case "GoBack":
          return packageSelection(
            state.payload.offerType,
            state.payload.loanOffer,
            state.payload.selectedOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            state.payload.rework
          );
        case "GoToStep":
          switch (action.step) {
            case "CreditChecks":
              return creditChecks(
                state.payload.loanOffer,
                option.some(state.payload.initialConsent),
                true,
                state.payload.rework,
                false,
                option.none
              );
            case "CustomerOffer":
              return customerOffer(
                state.payload.loanOffer,
                state.payload.personalDataOptions,
                state.payload.initialConsent,
                state.payload.cpiAdditionalQuestions,
                state.payload.rework
              );
            case "ExpensesAndAdditionalIncome":
              return expensesAndAdditionalIncome(
                state.payload.loanOffer,
                state.payload.personalDataOptions,
                state.payload.hasRefinancingCredits,
                state.payload.initialConsent,
                state.payload.cpiAdditionalQuestions,
                option.none,
                option.none,
                state.payload.rework
              );
            case "OfferList":
              return offerList(
                state.payload.offerType,
                state.payload.loanOffer,
                state.payload.personalDataOptions,
                state.payload.hasRefinancingCredits,
                state.payload.initialConsent,
                state.payload.cpiAdditionalQuestions,
                state.payload.rework
              );
            case "OfferReview":
              return offerReview(
                state.payload.offerType,
                state.payload.loanOffer,
                state.payload.selectedOffer,
                state.payload.personalDataOptions,
                state.payload.hasRefinancingCredits,
                state.payload.initialConsent,
                state.payload.cpiAdditionalQuestions,
                state.payload.rework
              );
            default:
              return state;
          }
        case "AccountsForRefinancing":
          return accountsForRefinancing(
            state.payload.offerType,
            state.payload.loanOffer,
            state.payload.selectedOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            state.payload.rework,
            true
          );
        case "KYC":
          return kyc(
            state.payload.offerType,
            state.payload.loanOffer,
            state.payload.selectedOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.cpiAdditionalQuestions,
            state.payload.initialConsent,
            option.none,
            state.payload.rework,
            true
          );
        case "FollowUpAndSignature":
          return followUpAndSignature(
            state.payload.offerType,
            state.payload.loanOffer,
            state.payload.selectedOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            false,
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            state.payload.rework,
            true,
            option.none
          );
        case "Reject":
          return rejected(action.payload.rejectionReason);
        default:
          return state;
      }
    case "AccountsForRefinancing":
      switch (action.type) {
        case "GoBack":
          return state.payload.showVirtualCard
            ? virtualCard(
                state.payload.offerType,
                state.payload.loanOffer,
                state.payload.selectedOffer,
                state.payload.personalDataOptions,
                state.payload.hasRefinancingCredits,
                state.payload.initialConsent,
                state.payload.cpiAdditionalQuestions,
                state.payload.rework
              )
            : offerReview(
                state.payload.offerType,
                state.payload.loanOffer,
                state.payload.selectedOffer,
                state.payload.personalDataOptions,
                state.payload.hasRefinancingCredits,
                state.payload.initialConsent,
                state.payload.cpiAdditionalQuestions,
                state.payload.rework
              );
        case "GoToStep":
          switch (action.step) {
            case "CreditChecks":
              return creditChecks(
                state.payload.loanOffer,
                option.some(state.payload.initialConsent),
                true,
                state.payload.rework,
                false,
                option.none
              );
            case "CustomerOffer":
              return customerOffer(
                state.payload.loanOffer,
                state.payload.personalDataOptions,
                state.payload.initialConsent,
                state.payload.cpiAdditionalQuestions,
                state.payload.rework
              );
            case "ExpensesAndAdditionalIncome":
              return expensesAndAdditionalIncome(
                state.payload.loanOffer,
                state.payload.personalDataOptions,
                state.payload.hasRefinancingCredits,
                state.payload.initialConsent,
                state.payload.cpiAdditionalQuestions,
                option.none,
                option.none,
                state.payload.rework
              );
            case "OfferList":
              return offerList(
                state.payload.offerType,
                state.payload.loanOffer,
                state.payload.personalDataOptions,
                state.payload.hasRefinancingCredits,
                state.payload.initialConsent,
                state.payload.cpiAdditionalQuestions,
                state.payload.rework
              );
            case "OfferReview":
              return offerReview(
                state.payload.offerType,
                state.payload.loanOffer,
                state.payload.selectedOffer,
                state.payload.personalDataOptions,
                state.payload.hasRefinancingCredits,
                state.payload.initialConsent,
                state.payload.cpiAdditionalQuestions,
                state.payload.rework
              );
            case "ReworkNextStep":
              return navigateReworkStep(state, "next");
            default:
              return state;
          }
        case "KYC":
          return kyc(
            state.payload.offerType,
            state.payload.loanOffer,
            state.payload.selectedOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.cpiAdditionalQuestions,
            state.payload.initialConsent,
            option.none,
            state.payload.rework
          );
        case "FollowUpAndSignature":
          return followUpAndSignature(
            state.payload.offerType,
            state.payload.loanOffer,
            state.payload.selectedOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            false,
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            state.payload.rework,
            true,
            option.none
          );
        case "Reject":
          return rejected(action.payload.rejectionReason);
        default:
          return state;
      }
    case "KYC":
      switch (action.type) {
        case "GoBack":
          return pipe(
            state.payload.hasRefinancingCredits,
            boolean.fold<State>(
              () =>
                state.payload.showVirtualCard
                  ? virtualCard(
                      state.payload.offerType,
                      state.payload.loanOffer,
                      state.payload.selectedOffer,
                      state.payload.personalDataOptions,
                      state.payload.hasRefinancingCredits,
                      state.payload.initialConsent,
                      state.payload.cpiAdditionalQuestions,
                      state.payload.rework
                    )
                  : offerReview(
                      state.payload.offerType,
                      state.payload.loanOffer,
                      state.payload.selectedOffer,
                      state.payload.personalDataOptions,
                      state.payload.hasRefinancingCredits,
                      state.payload.initialConsent,
                      state.payload.cpiAdditionalQuestions,
                      state.payload.rework
                    ),
              () =>
                accountsForRefinancing(
                  state.payload.offerType,
                  state.payload.loanOffer,
                  state.payload.selectedOffer,
                  state.payload.personalDataOptions,
                  state.payload.hasRefinancingCredits,
                  state.payload.initialConsent,
                  state.payload.cpiAdditionalQuestions,
                  state.payload.rework,
                  state.payload.showVirtualCard
                )
            )
          );
        case "GoToStep":
          switch (action.step) {
            case "CreditChecks":
              return creditChecks(
                state.payload.loanOffer,
                option.some(state.payload.initialConsent),
                true,
                state.payload.rework,
                false,
                option.none
              );
            case "CustomerOffer":
              return customerOffer(
                state.payload.loanOffer,
                state.payload.personalDataOptions,
                state.payload.initialConsent,
                state.payload.cpiAdditionalQuestions,
                state.payload.rework
              );
            case "ExpensesAndAdditionalIncome":
              return expensesAndAdditionalIncome(
                state.payload.loanOffer,
                state.payload.personalDataOptions,
                state.payload.hasRefinancingCredits,
                state.payload.initialConsent,
                state.payload.cpiAdditionalQuestions,
                option.none,
                option.none,
                state.payload.rework
              );
            case "OfferList":
              return offerList(
                state.payload.offerType,
                state.payload.loanOffer,
                state.payload.personalDataOptions,
                state.payload.hasRefinancingCredits,
                state.payload.initialConsent,
                state.payload.cpiAdditionalQuestions,
                state.payload.rework
              );
            case "OfferReview":
              return offerReview(
                state.payload.offerType,
                state.payload.loanOffer,
                state.payload.selectedOffer,
                state.payload.personalDataOptions,
                state.payload.hasRefinancingCredits,
                state.payload.initialConsent,
                state.payload.cpiAdditionalQuestions,
                state.payload.rework
              );
            default:
              return state;
          }
        case "FollowUpAndSignature":
          return followUpAndSignature(
            state.payload.offerType,
            state.payload.loanOffer,
            state.payload.selectedOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.isKycNeeded,
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            state.payload.rework,
            true,
            option.none
          );
        case "Micropayment":
          return micropayment(
            state.payload.offerType,
            state.payload.loanOffer,
            state.payload.selectedOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            state.payload.rework
          );
        case "Reject":
          return rejected(action.payload.rejectionReason);
        default:
          return state;
      }
    case "Micropayment":
      switch (action.type) {
        case "GoBack":
          return kyc(
            state.payload.offerType,
            state.payload.loanOffer,
            state.payload.selectedOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.cpiAdditionalQuestions,
            state.payload.initialConsent,
            option.none,
            state.payload.rework
          );
        case "GoToStep":
          switch (action.step) {
            case "CreditChecks":
              return creditChecks(
                state.payload.loanOffer,
                option.some(state.payload.initialConsent),
                true,
                state.payload.rework,
                false,
                option.none
              );
            case "CustomerOffer":
              return customerOffer(
                state.payload.loanOffer,
                state.payload.personalDataOptions,
                state.payload.initialConsent,
                state.payload.cpiAdditionalQuestions,
                state.payload.rework
              );
            case "ExpensesAndAdditionalIncome":
              return expensesAndAdditionalIncome(
                state.payload.loanOffer,
                state.payload.personalDataOptions,
                state.payload.hasRefinancingCredits,
                state.payload.initialConsent,
                state.payload.cpiAdditionalQuestions,
                option.none,
                option.none,
                state.payload.rework
              );
            case "OfferList":
              return offerList(
                state.payload.offerType,
                state.payload.loanOffer,
                state.payload.personalDataOptions,
                state.payload.hasRefinancingCredits,
                state.payload.initialConsent,
                state.payload.cpiAdditionalQuestions,
                state.payload.rework
              );
            case "OfferReview":
              return offerReview(
                state.payload.offerType,
                state.payload.loanOffer,
                state.payload.selectedOffer,
                state.payload.personalDataOptions,
                state.payload.hasRefinancingCredits,
                state.payload.initialConsent,
                state.payload.cpiAdditionalQuestions,
                state.payload.rework
              );
            case "KYC":
              return kyc(
                state.payload.offerType,
                state.payload.loanOffer,
                state.payload.selectedOffer,
                state.payload.personalDataOptions,
                state.payload.hasRefinancingCredits,
                state.payload.cpiAdditionalQuestions,
                state.payload.initialConsent,
                option.none,
                state.payload.rework
              );
            default:
              return state;
          }
        case "Reject":
          return rejected(action.payload.rejectionReason);
        case "Summary":
          return summary(
            action.payload.productType,
            action.payload.hasRefinancing,
            action.payload.lfStatus
          );
        case "FollowUpAndSignature":
          return followUpAndSignature(
            state.payload.offerType,
            state.payload.loanOffer,
            state.payload.selectedOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            true, // Comes from micropayment which is called only by KYC
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            state.payload.rework,
            true,
            option.none
          );
        default:
          return state;
      }
    case "FollowUpAndSignature":
      switch (action.type) {
        case "Reject":
          return rejected(action.payload.rejectionReason);
        case "Summary":
          return summary(
            action.payload.productType,
            action.payload.hasRefinancing,
            action.payload.lfStatus
          );
        case "GoBack":
          return kyc(
            state.payload.offerType,
            state.payload.loanOffer,
            state.payload.selectedOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.cpiAdditionalQuestions,
            state.payload.initialConsent,
            option.none,
            state.payload.rework
          );
        case "UploadDocuments":
          return uploadDocuments(
            state.payload.selectedOffer.productType,
            state.payload.hasRefinancingCredits,
            action.payload.lfStatus,
            state.payload.loanOffer,
            state.payload.rework
          );
        case "ReloadSuspense":
          return reloadSuspense(
            state.payload.offerType,
            state.payload.loanOffer,
            state.payload.selectedOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.cpiAdditionalQuestions,
            state.payload.initialConsent,
            option.none,
            state.payload.rework
          );
        case "AfterSignature":
          return afterSignature(
            state.payload.offerType,
            state.payload.loanOffer,
            state.payload.selectedOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            true,
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            state.payload.rework,
            true,
            option.none,
            action.payload.lfStatus
          );

        default:
          return state;
      }
    case "Rejected":
      return state;
    case "MicropaymentOverview":
      return state;
    case "Summary":
      switch (action.type) {
        case "MicropaymentOverview":
          return micropaymentOverview();
        default:
          return state;
      }
    case "MaxNumberChangesReached":
      return maxNumberChangesReached();
    case "UploadDocuments":
      switch (action.type) {
        case "Summary":
          return summary(
            state.payload.productType,
            state.payload.hasRefinancing,
            state.payload.lfStatus
          );
        case "GoToStep":
          switch (action.step) {
            case "ReworkPreviousStep":
              return navigateReworkStep(state, "prev");
            case "ReworkNextStep":
              return navigateReworkStep(state, "next");
            default:
              return state;
          }
        case "Reject":
          return rejected(action.payload.rejectionReason);
        default:
          return state;
      }
    case "PersonalData":
      switch (action.type) {
        case "GoBack":
          return state;
        case "GoToStep":
          switch (action.step) {
            case "ReworkNextStep":
              return navigateReworkStep(state, "next");
            default:
              return state;
          }
        case "Reject":
          return rejected(action.payload.rejectionReason);
        default:
          return state;
      }
    case "ReloadSuspense":
      switch (action.type) {
        case "FollowUpAndSignature":
          return followUpAndSignature(
            state.payload.offerType,
            state.payload.loanOffer,
            state.payload.selectedOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            true, // Comes from micropayment which is called only by KYC
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            state.payload.rework,
            true,
            option.none
          );
        default:
          return state;
      }
    case "AfterSignature":
      switch (action.type) {
        case "FollowUpAndSignature":
          return followUpAndSignature(
            state.payload.offerType,
            state.payload.loanOffer,
            state.payload.selectedOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.isKycNeeded,
            state.payload.initialConsent,
            state.payload.cpiAdditionalQuestions,
            state.payload.rework,
            action.payload.askForCredentials === undefined
              ? state.payload.shouldAskCredential
              : action.payload.askForCredentials,
            option.none
          );
        case "UploadDocuments":
          return uploadDocuments(
            state.payload.selectedOffer.productType,
            state.payload.hasRefinancingCredits,
            action.payload.lfStatus,
            state.payload.loanOffer,
            state.payload.rework
          );
        case "Summary":
          return summary(
            action.payload.productType,
            action.payload.hasRefinancing,
            action.payload.lfStatus
          );
        case "Reject":
          return rejected(action.payload.rejectionReason);
        case "ReloadSuspense":
          return reloadSuspense(
            state.payload.offerType,
            state.payload.loanOffer,
            state.payload.selectedOffer,
            state.payload.personalDataOptions,
            state.payload.hasRefinancingCredits,
            state.payload.cpiAdditionalQuestions,
            state.payload.initialConsent,
            option.none,
            state.payload.rework
          );
        default:
          return state;
      }
    case "ExistingClientSuspense":
      switch (action.type) {
        case "ExistingClient":
          return existingClient(action.reason);
        case "ExistingApplication3PError":
          return existingApplication3PError();
        case "CreditChecks":
          if (option.isSome(state.payload.loanOffer)) {
            return creditChecks(
              state.payload.loanOffer.value,
              option.none,
              false,
              emptyRework,
              false,
              option.none
            );
          }
          return state;
        case "RestoreApplication":
          return restoreApplication(action.payload);
        case "Reject":
          return rejected(action.payload.rejectionReason);
        case "VerifyPhoneAndEmail":
          return verifyPhoneAndEmail(state.payload.loanOffer);
        case "Landing":
          return landing();
        case "RestoreStep":
          return restoreState(
            action.payload.restoredData,
            action.payload.isKycNeeded,
            action.payload.rework,
            action.payload.applicationStatus,
            action.payload.signatureStatus
          );
        case "ApplicationLocked":
          return applicationLocked();
        default:
          return state;
      }
  }
}

export function shouldShowFormsOnExit(
  state: State,
  isCreditChecksConfirmed: boolean
): boolean {
  switch (state.type) {
    case "Loading":
    case "CustomerOffer":
    case "ExpensesAndAdditionalIncome":
    case "CheckAdditionalIncomes":
    case "OfferList":
    case "CheckExpensesOffers":
    case "SendAdditionalIncome":
    case "OfferReview":
    case "OfferReviewPush":
    case "PackageSelection":
    case "VirtualCard":
    case "AccountsForRefinancing":
    case "KYC":
    case "FollowUpAndSignature":
    case "UploadDocuments":
    case "PersonalData":
    case "AfterSignature":
    case "Micropayment":
    case "ReloadSuspense":
    case "ExistingClientSuspense":
    case "SaveCustomerOfferSuspense":
      return true;
    case "Landing":
    case "PhoneAndEmailVerification":
    case "Select3P":
    case "AddPhoneNumber":
    case "UploadID":
    case "ExistingClient":
    case "ExistingApplication3PError":
    case "RestoreApplication":
    case "Rejected":
    case "MicropaymentOverview":
    case "Summary":
    case "MaxNumberChangesReached":
    case "ApplicationLocked":
    case "Error":
      return false;
    case "CreditChecks":
      return isCreditChecksConfirmed;
  }
}

export function reasonNotSavedOnExit(state: State): LocaleKey | undefined {
  switch (state.type) {
    case "Landing":
    case "PhoneAndEmailVerification":
    case "UploadID":
    case "CreditChecks":
      return "StandardLoan.ApplicationNotSaved.reason.exitTooEarly";
    case "ExistingClient":
    case "ExistingApplication3PError":
    case "RestoreApplication":
    case "Rejected":
    case "MicropaymentOverview":
    case "Summary":
    case "MaxNumberChangesReached":
    default:
      return undefined;
  }
}

export function handleRejectableSteps(
  restoredData: RejectableSteps,
  nextState: (offerType: OfferType) => State
): State {
  return pipe(
    restoredData.proceedNextResponse,
    option.getOrElse<OfferTypeOutput>(() => ({ lfDecision: "Approved" })),
    option.fromPredicate(decision => decision.lfDecision !== "Rejected"),
    option.map(
      decision => decision.lfDecision as Exclude<OfferTypeDecision, "Rejected">
    ),
    option.fold(constant(rejected("CBRejected")), nextState)
  );
}

export function restoreState(
  restoredData: RestoreNewestLoanApplicationOutput,
  isKycNeeded: boolean,
  rework: Rework,
  applicationStatus: Option<ApplicationStatus>,
  signatureStatus: SignatureStatus
): State {
  const loanOffer = {
    amount: restoredData.genericLoanResponse.amount,
    tenor: restoredData.genericLoanResponse.tenor,
    currency: restoredData.genericLoanResponse.currency,
  };

  switch (restoredData.festep) {
    case "CREDIT_CHECKS":
      return creditChecks(
        loanOffer,
        option.none,
        true,
        rework,
        false,
        option.none
      );
    case "REVIEW_INFORMATION":
      return creditChecks(
        loanOffer,
        option.some(restoredData.startStandardLoanApplicationRequest),
        true,
        rework,
        false,
        option.none
      );
    case "FINALISE_OFFER_MAIN_SCREEN":
      return pipe(
        restoredData.incomeAndPersonalData.personalData,
        option.fold(
          () => landing() as State,
          personalDataOptions =>
            customerOffer(
              loanOffer,
              personalDataOptions.personalData,
              restoredData.startStandardLoanApplicationRequest,
              restoredData.additionalQuestionsCPIRequest,
              rework
            )
        )
      );

    case "EXPENSE":
      return pipe(
        restoredData.incomeAndPersonalData.personalData,
        option.fold(
          () => landing() as State,
          personalDataOptions =>
            expensesAndAdditionalIncome(
              loanOffer,
              personalDataOptions.personalData,
              getHasRefinancingCredits(restoredData.creditBureauStatusResponse),
              restoredData.startStandardLoanApplicationRequest,
              restoredData.additionalQuestionsCPIRequest,
              option.none,
              option.none,
              rework
            )
        )
      );

    case "ADDITIONAL_INCOME":
      return pipe(
        restoredData.incomeAndPersonalData.personalData,
        option.fold(
          () => landing() as State,
          personalDataOptions =>
            expensesAndAdditionalIncome(
              loanOffer,
              personalDataOptions.personalData,
              getHasRefinancingCredits(restoredData.creditBureauStatusResponse),
              restoredData.startStandardLoanApplicationRequest,
              restoredData.additionalQuestionsCPIRequest,
              option.some(true),
              option.some(true),
              rework
            )
        )
      );

    case "CHOOSE_OFFER":
      return pipe(
        restoredData.incomeAndPersonalData.personalData,
        option.fold(
          () => landing() as State,
          personalDataOptions =>
            handleRejectableSteps(restoredData, offerType =>
              offerList(
                offerType,
                loanOffer,
                personalDataOptions.personalData,
                getHasRefinancingCredits(
                  restoredData.creditBureauStatusResponse
                ),
                restoredData.startStandardLoanApplicationRequest,
                restoredData.additionalQuestionsCPIRequest,
                rework
              )
            )
        )
      );
    case "REVIEW_OFFER":
      // @TODO: where to get the approved/counteroffer offerType?
      return pipe(
        restoredData.incomeAndPersonalData.personalData,
        option.fold(
          () => landing() as State,
          personalDataOptions =>
            handleRejectableSteps(restoredData, offerType =>
              offerReview(
                offerType,
                loanOffer,
                restoredData.genericLoanResponse,
                personalDataOptions.personalData,
                getHasRefinancingCredits(
                  restoredData.creditBureauStatusResponse
                ),
                restoredData.startStandardLoanApplicationRequest,
                restoredData.additionalQuestionsCPIRequest,
                rework
              )
            )
        )
      );

    case "AUTHORIZE_PUSH":
      // @TODO: where to get the approved/counteroffer offerType?
      return pipe(
        restoredData.incomeAndPersonalData.personalData,
        option.fold(
          () => landing() as State,
          personalDataOptions =>
            handleRejectableSteps(restoredData, offerType =>
              offerReviewPush(
                offerType,
                loanOffer,
                restoredData.genericLoanResponse,
                personalDataOptions.personalData,
                getHasRefinancingCredits(
                  restoredData.creditBureauStatusResponse
                ),
                restoredData.startStandardLoanApplicationRequest,
                restoredData.additionalQuestionsCPIRequest,
                rework,
                restoredData.genericLoanResponse.disbursementAccount
              )
            )
        )
      );
    case "REMOTE_SIGNATURE":
      // @TODO: where to get the approved/counteroffer offerType?
      return pipe(
        restoredData.incomeAndPersonalData.personalData,
        option.fold(
          () => landing() as State,
          personalDataOptions =>
            handleRejectableSteps(restoredData, offerType =>
              offerReviewPush(
                offerType,
                loanOffer,
                restoredData.genericLoanResponse,
                personalDataOptions.personalData,
                getHasRefinancingCredits(
                  restoredData.creditBureauStatusResponse
                ),
                restoredData.startStandardLoanApplicationRequest,
                restoredData.additionalQuestionsCPIRequest,
                rework,
                restoredData.genericLoanResponse.disbursementAccount
              )
            )
        )
      );
    case "KYC":
      return pipe(
        restoredData.incomeAndPersonalData.personalData,
        option.fold(
          () => landing() as State,
          personalDataOptions =>
            handleRejectableSteps(restoredData, offerType =>
              kyc(
                offerType,
                loanOffer,
                restoredData.genericLoanResponse,
                personalDataOptions.personalData,
                getHasRefinancingCredits(
                  restoredData.creditBureauStatusResponse
                ),
                restoredData.additionalQuestionsCPIRequest,
                restoredData.startStandardLoanApplicationRequest,
                option.none,
                rework
              )
            )
        )
      );
    case "PERSONAL_DATA":
      return pipe(
        restoredData.incomeAndPersonalData.personalData,
        option.fold(
          () => landing() as State,
          personalDataOptions =>
            handleRejectableSteps(restoredData, offerType =>
              kyc(
                offerType,
                loanOffer,
                restoredData.genericLoanResponse,
                personalDataOptions.personalData,
                getHasRefinancingCredits(
                  restoredData.creditBureauStatusResponse
                ),
                restoredData.additionalQuestionsCPIRequest,
                restoredData.startStandardLoanApplicationRequest,
                pipe(
                  restoredData.standardLoanKycRequest,
                  option.map(kycReq => kycReq.answers.transactionsInfo)
                ),
                rework
              )
            )
        )
      );
    case "MICROPAYMENT":
      return pipe(
        restoredData.incomeAndPersonalData.personalData,
        option.fold(
          () => landing() as State,
          personalDataOptions =>
            handleRejectableSteps(restoredData, offerType =>
              micropayment(
                offerType,
                loanOffer,
                restoredData.genericLoanResponse,
                personalDataOptions.personalData,
                getHasRefinancingCredits(
                  restoredData.creditBureauStatusResponse
                ),
                restoredData.startStandardLoanApplicationRequest,
                restoredData.additionalQuestionsCPIRequest,
                rework
              )
            )
        )
      );
    case "PACKAGE_SELECTION":
      return pipe(
        restoredData.incomeAndPersonalData.personalData,
        option.fold(
          () => landing() as State,
          personalDataOptions =>
            handleRejectableSteps(restoredData, offerType =>
              packageSelection(
                offerType,
                loanOffer,
                restoredData.genericLoanResponse,
                personalDataOptions.personalData,
                getHasRefinancingCredits(
                  restoredData.creditBureauStatusResponse
                ),
                restoredData.startStandardLoanApplicationRequest,
                restoredData.additionalQuestionsCPIRequest,
                rework
              )
            )
        )
      );
    case "VIRTUAL_CARDS":
      return pipe(
        restoredData.incomeAndPersonalData.personalData,
        option.fold(
          () => landing() as State,
          personalDataOptions =>
            handleRejectableSteps(restoredData, offerType =>
              virtualCard(
                offerType,
                loanOffer,
                restoredData.genericLoanResponse,
                personalDataOptions.personalData,
                getHasRefinancingCredits(
                  restoredData.creditBureauStatusResponse
                ),
                restoredData.startStandardLoanApplicationRequest,
                restoredData.additionalQuestionsCPIRequest,
                rework
              )
            )
        )
      );
    case "ACCOUNTS_FOR_REFINANCING":
      return pipe(
        restoredData.incomeAndPersonalData.personalData,
        option.fold(
          () => landing() as State,
          personalDataOptions =>
            handleRejectableSteps(restoredData, offerType =>
              accountsForRefinancing(
                offerType,
                loanOffer,
                restoredData.genericLoanResponse,
                personalDataOptions.personalData,
                getHasRefinancingCredits(
                  restoredData.creditBureauStatusResponse
                ),
                restoredData.startStandardLoanApplicationRequest,
                restoredData.additionalQuestionsCPIRequest,
                rework
              )
            )
        )
      );
    case "CLIENT_CREDENTIALS":
      return pipe(
        restoredData.incomeAndPersonalData.personalData,
        option.fold(
          () => landing() as State,
          personalDataOptions =>
            handleRejectableSteps(restoredData, offerType =>
              followUpAndSignature(
                offerType,
                loanOffer,
                restoredData.genericLoanResponse,
                personalDataOptions.personalData,
                getHasRefinancingCredits(
                  restoredData.creditBureauStatusResponse
                ),
                isKycNeeded,
                restoredData.startStandardLoanApplicationRequest,
                restoredData.additionalQuestionsCPIRequest,
                rework,
                restoredData.festep === "CLIENT_CREDENTIALS",
                option.some(restoredData)
              )
            )
        )
      );
    case "SIGNATURE":
      return pipe(
        restoredData.incomeAndPersonalData.personalData,
        option.fold(
          () => landing() as State,
          personalDataOptions =>
            pipe(
              signatureStatus === "NONE",
              boolean.fold(
                () =>
                  handleRejectableSteps(restoredData, offerType =>
                    afterSignature(
                      offerType,
                      loanOffer,
                      restoredData.genericLoanResponse,
                      personalDataOptions.personalData,
                      getHasRefinancingCredits(
                        restoredData.creditBureauStatusResponse
                      ),
                      isKycNeeded,
                      restoredData.startStandardLoanApplicationRequest,
                      restoredData.additionalQuestionsCPIRequest,
                      rework,
                      restoredData.festep === "CLIENT_CREDENTIALS",
                      option.some(restoredData),
                      option.of("Approved")
                    )
                  ),
                () =>
                  handleRejectableSteps(restoredData, offerType =>
                    followUpAndSignature(
                      offerType,
                      loanOffer,
                      restoredData.genericLoanResponse,
                      personalDataOptions.personalData,
                      getHasRefinancingCredits(
                        restoredData.creditBureauStatusResponse
                      ),
                      isKycNeeded,
                      restoredData.startStandardLoanApplicationRequest,
                      restoredData.additionalQuestionsCPIRequest,
                      rework,
                      restoredData.festep === "CLIENT_CREDENTIALS",
                      option.some(restoredData)
                    )
                  )
              )
            )
        )
      );
    case "UPLOAD_DOCUMENTS":
      return pipe(
        restoredData.incomeAndPersonalData.personalData,
        option.fold(
          () => landing(),
          (): State =>
            handleRejectableSteps(restoredData, _ =>
              uploadDocuments(
                restoredData.genericLoanResponse.productType,
                getHasRefinancingCredits(
                  restoredData.creditBureauStatusResponse
                ),
                option.none,
                loanOffer,
                rework
              )
            )
        )
      );
    case "FINAL_PAGE":
      return summary(
        restoredData.genericLoanResponse.productType,
        getHasRefinancingCredits(restoredData.creditBureauStatusResponse),
        // option.none // TODO Simone: Is it ok?
        pipe(
          applicationStatus,
          option.fold(() => option.none, toLfStatus)
        )
      );
    case "UNDER_REWORK":
      return pipe(
        rework.reworkSteps,
        option.fold(
          () => landing(), // @TODO: KO page? Shouldn't happen
          steps => reworkStep(rework, loanOffer, steps[0])
        )
      );
  }
}

function toLfStatus(applicationStatus: ApplicationStatus): Option<LFStatus> {
  switch (applicationStatus) {
    case "APPROVED":
      return option.some("Approved" as LFStatus);
    case "REJECTED":
      return option.some("Rejected" as LFStatus);
    default:
      return option.none;
  }
}

function getHasRefinancingCredits(
  creditBurauResponse: CreditBureauStatus
): boolean {
  if (
    creditBurauResponse.cbResultReady &&
    creditBurauResponse.cbDecision === "Approved"
  )
    return creditBurauResponse.hasRefinancingCredits;

  return false;
}

function navigateReworkStep(
  state:
    | PersonalDataState
    | UploadDocumentsState
    | FollowUpAndSignatureState
    | AccountsForRefinancingState
    | ExpensesAndAdditionalIncomeState
    | OfferReviewState
    | CreditChecksState,
  direction: "prev" | "next"
) {
  const stateToReworkStepName = (): ReworkStep => {
    switch (state.type) {
      case "AccountsForRefinancing":
        return "ACCOUNTS_FOR_REFINANCING";
      case "UploadDocuments":
        return "UPLOAD_DOCUMENTS";
      case "FollowUpAndSignature":
        return "AUTHORIZATION_PAGE";
      case "PersonalData":
        return "PERSONAL_DATA";
      case "ExpensesAndAdditionalIncome":
        return reworkStepsContainStep(
          state.payload.rework.reworkSteps,
          "ADDITIONAL_INCOME"
        )
          ? "ADDITIONAL_INCOME"
          : "INCOME_AND_PERSONAL_DATA";
      case "CreditChecks":
        return "INCOME_AND_PERSONAL_DATA";
      case "OfferReview":
        return reworkStepsContainStep(
          state.payload.rework.reworkSteps,
          "ADDITIONAL_INCOME"
        )
          ? "ADDITIONAL_INCOME"
          : "INCOME_AND_PERSONAL_DATA";
    }
  };
  return pipe(
    state.payload.rework.reworkSteps,
    option.fold(
      () => state,
      reworkSteps => {
        const index =
          reworkSteps.findIndex(step => step === stateToReworkStepName()) +
          (direction === "next" ? 1 : -1);

        if (index >= 0 && index < reworkSteps.length) {
          return reworkStep(
            state.payload.rework,
            state.payload.loanOffer,
            reworkSteps[index]
          );
        } else return state;
      }
    )
  );
}

function reworkStepsContainStep(
  reworkSteps: Option<NonEmptyArray<ReworkStep>>,
  step: ReworkStep
) {
  return pipe(
    reworkSteps,
    option.fold(
      () => false,
      reworkSteps =>
        reworkSteps.findIndex(reworkStep => reworkStep === step) > -1
    )
  );
}

function reworkStep(
  rework: Rework,
  loanOffer: LoanOffer,
  stepToRestore: ReworkStep
) {
  return pipe(
    rework.newValues,
    option.fold(
      () => landing(),
      (restoredData): State => {
        switch (stepToRestore) {
          case "ACCOUNTS_FOR_REFINANCING":
            return pipe(
              restoredData.incomeAndPersonalData.personalData,
              option.fold(
                () => landing(),
                (personalDataOptions): State =>
                  handleRejectableSteps(restoredData, offerType =>
                    accountsForRefinancing(
                      offerType,
                      loanOffer,
                      restoredData.genericLoanResponse,
                      personalDataOptions.personalData,
                      getHasRefinancingCredits(
                        restoredData.creditBureauStatusResponse
                      ),
                      restoredData.startStandardLoanApplicationRequest,
                      restoredData.additionalQuestionsCPIRequest,
                      rework
                    )
                  )
              )
            );

          case "ADDITIONAL_INCOME":
            return pipe(
              restoredData.incomeAndPersonalData.personalData,
              option.fold(
                () => landing(),
                (personalDataOptions): State =>
                  expensesAndAdditionalIncome(
                    loanOffer,
                    personalDataOptions.personalData,
                    getHasRefinancingCredits(
                      restoredData.creditBureauStatusResponse
                    ),
                    restoredData.startStandardLoanApplicationRequest,
                    restoredData.additionalQuestionsCPIRequest,
                    option.some(true),
                    option.some(true),
                    rework
                  )
              )
            );

          case "INCOME_AND_PERSONAL_DATA":
            return creditChecks(
              loanOffer,
              option.some(restoredData.startStandardLoanApplicationRequest),
              false,
              rework,
              getHasRefinancingCredits(restoredData.creditBureauStatusResponse),
              restoredData.additionalQuestionsCPIRequest
            );

          case "UPLOAD_DOCUMENTS":
            return uploadDocuments(
              restoredData.genericLoanResponse.productType,
              getHasRefinancingCredits(restoredData.creditBureauStatusResponse),
              option.none,
              loanOffer,
              rework
            );

          case "PERSONAL_DATA":
            return personalData(
              loanOffer,
              pipe(
                restoredData.personalDataConfirmation,
                option.chain(pd => {
                  const { primary, secondary } = pd;
                  const documentPayload = mergeExtractDataDocumentsOutput(
                    primary,
                    secondary
                  );

                  return pipe(
                    documentPayload,
                    option.map(document => ({
                      ...document,
                      canUploadAgain: false,
                      canEdit: false,
                      showWarning: false,
                      fraudCheck: option.none,
                    }))
                  );
                })
              ),
              rework
            );

          case "AUTHORIZATION_PAGE":
            return pipe(
              restoredData.incomeAndPersonalData.personalData,
              option.fold(
                () => landing(),
                (personalDataOptions): State =>
                  handleRejectableSteps(restoredData, offerType =>
                    followUpAndSignature(
                      offerType,
                      loanOffer,
                      restoredData.genericLoanResponse,
                      personalDataOptions.personalData,
                      getHasRefinancingCredits(
                        restoredData.creditBureauStatusResponse
                      ),
                      false,
                      restoredData.startStandardLoanApplicationRequest,
                      restoredData.additionalQuestionsCPIRequest,
                      rework,
                      false,
                      option.some(restoredData)
                    )
                  )
              )
            );
        }
      }
    )
  );
}
