import {
  Banner,
  Body,
  Box,
  buttonLink,
  Card,
  ContentRow,
  ErrorBanner,
  FileContent,
  Heading,
  Loader,
  LoadingButton,
  Stack,
  useIsMobileLayout,
  Space,
  FeedbackDialog,
} from "design-system";
import { constVoid, pipe, constTrue, constFalse } from "fp-ts/function";
import * as api from "../api";
import { PageHeading } from "../../Common/PageHeading/PageHeading";
import { useFormatMessage } from "../../intl";
import { useCommand, usePollingEffect, useQuery } from "../../useAPI";
import { UploadDocumentsSection } from "./UploadDocumentsSection";
import { TaskEither } from "fp-ts/TaskEither";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import {
  nonEmptyArray,
  option,
  array,
  taskEither,
  boolean,
  either,
} from "fp-ts";
import * as uploadDocumentsApi from "./api";
import { useMemo, useState } from "react";
import {
  decodeFileType,
  encodeFileToBase64,
  fileTypes,
  mapSubmitDocumentStatus,
  mapSubmitNoteStatus,
} from "../../MortgageDashboard/Documents/domain";
import { selectedMainApplicant } from "../../MortgageDashboard/mortgageDashboardUtils";
import { Option } from "fp-ts/Option";
import { DocumentMime } from "../../Common/documentAPI";
import { UUID } from "io-ts-types/lib/UUID";
import { StandardLoanDocumentUploadDialog } from "./StandardLoanDocumentUploadDialog";
import { UploadDocumentFlowType } from "../../globalDomain";
import * as remoteData from "../../RemoteData";
import { OptionalDocumentUploadDialog } from "./OptionalDocumentUploadDialog";
import { OptionalDocumentsSection } from "./OptionalDocumentsSection";
import { useAppContext } from "../../useAppContext";
import { NewRequiredDocumentsSection } from "./NewRequiredDocumentsSection";
import { DocumentNoteUploadError } from "../../Common/Dialogs/DocumentUploadDialog/domain";
import { ReworkBanner } from "../Rework/ReworkBanner";
import * as reworkApi from "../Rework/api";
import { useRemoteData } from "../../useRemoteData";
import { StepCommentsOutput } from "../Rework/api";
import { MainContent } from "../../Common/MainContent";

type Props = {
  isClientFlow: boolean;
  bankerFlowId: Option<UUID>;
  onNext: TaskEither<unknown, unknown>;
  productType: Option<UploadDocumentFlowType>;
  requiredDocumentsUpToDate: boolean;
};

export function UploadDocumentsRework(props: Props) {
  return pipe(
    props.requiredDocumentsUpToDate,
    boolean.fold(
      () => <RefreshDocumentsWithAppState {...props} />,
      () => <UploadDocuments {...props} />
    )
  );
}

function RefreshDocumentsWithAppState(props: Props) {
  const [appStateRequiredDocuments] = useQuery(api.appStateRequiredDocuments);

  return pipe(
    appStateRequiredDocuments,
    remoteData.fold(
      () => <LoadingWrapped />,
      () => <LoadingError />,
      () => {
        return <RefreshDocumentsWithCheckIfReady {...props} />;
      }
    )
  );
}

function getLoading() {
  const loading = (
    <Box hAlignContent="center">
      <Loader />
    </Box>
  );

  return loading;
}

function LoadingError() {
  const formatMessage = useFormatMessage();

  const loadingError = (
    <ErrorBanner>
      {formatMessage("StandardLoan.UploadDocuments.loadingError")}
    </ErrorBanner>
  );
  return loadingError;
}

function LoadingWrapped() {
  const formatMessage = useFormatMessage();
  return (
    <MainContent>
      <PageHeading
        title={formatMessage("StandardLoan.UploadDocuments.waiting.title")}
        hideOnMobile
      />
      {getLoading()}
    </MainContent>
  );
}

function RefreshDocumentsWithCheckIfReady(props: Props) {
  const [documentsRefreshed, setDocumentsRefreshed] = useState(false);
  const [showError, setShowError] = useState(false);

  usePollingEffect(api.checkRequiredDocuments, {
    intervalMS: 1000,
    disabled: false,
    shouldPollingContinue: ({ requiredDocumentsReceived }) =>
      !requiredDocumentsReceived,
    onSuccess: ({ requiredDocumentsReceived }) =>
      requiredDocumentsReceived && setDocumentsRefreshed(true),
    onError: () => {
      setShowError(true);
    },
  });

  return showError ? (
    <LoadingError />
  ) : documentsRefreshed ? (
    <UploadDocuments {...props} />
  ) : (
    <LoadingWrapped />
  );
}

function UploadDocuments(props: Props) {
  const formatMessage = useFormatMessage();
  const [requiredDocuments, refreshList] = useQuery(api.requiredDocuments);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const isMobileLayout = useIsMobileLayout();
  const confirmUpload = useCommand(uploadDocumentsApi.confirmUpload);
  const removeDocument = useCommand(
    props.isClientFlow ? api.removeDocumentByClient : api.removeDocument
  );
  const cancelUpload = useCommand(
    props.isClientFlow ? api.cancelUploadByClient : api.cancelUpload
  );
  const uploadDocument = useCommand(
    props.isClientFlow ? api.uploadDocumentByClient : api.uploadDocument
  );
  const confirmDocumentUpload = useCommand(
    props.isClientFlow ? api.confirmDocumentByClient : api.confirmDocument
  );
  const checkNewRequiredDocument = useCommand(
    props.isClientFlow
      ? api.checkNewRequiredDocumentByClient
      : api.checkNewRequiredDocument
  );
  const confirmNewRequiredDocument = useCommand(
    props.isClientFlow
      ? api.confirmNewRequiredDocumentUploadByClient
      : api.confirmNewRequiredDocumentUpload
  );
  const checkNewOptionalDocument = useCommand(
    props.isClientFlow
      ? api.checkNewOptionalDocumentByClient
      : api.checkNewOptionalDocument
  );
  const checkOptionalDocument = useCommand(
    props.isClientFlow
      ? api.checkOptionalDocumentByClient
      : api.checkOptionalDocument
  );
  const confirmOptionalDocument = useCommand(
    props.isClientFlow
      ? api.confirmOptionalDocumentUploadByClient
      : api.confirmOptionalDocumentUpload
  );
  const cancelOptionalDocument = useCommand(
    props.isClientFlow
      ? api.cancelOptionalDocUploadByClient
      : api.cancelOptionalDocUpload
  );
  const removeOptionalDocument = useCommand(
    props.isClientFlow ? api.removeOptionalDocByClient : api.removeOptionalDoc
  );
  const cancelNewRequiredDocument = useCommand(
    props.isClientFlow
      ? api.cancelNewRequiredDocUploadByClient
      : api.cancelNewRequiredDocUpload
  );
  const removeNewRequiredDocument = useCommand(
    props.isClientFlow
      ? api.removeNewRequiredDocByClient
      : api.removeNewRequiredDoc
  );

  const sendLinkToClient = useCommand(api.sendLinkToClient);
  const [isLinkSent, setIsLinkSent] = useState<boolean>(false);
  const [sentLinkConfirmation, showSentLinkConfirmation] = useState<boolean>(
    false
  );
  const [uploadDialogDocument, setUploadDialogDocument] = useState<
    Option<api.UploadedDocumentData>
  >(option.none);
  const [
    uploadNewOptionalDocumentDialog,
    setUploadNewOptionalDocumentDialog,
  ] = useState<boolean>(false);

  const [
    uploadOptionalDocumentDialog,
    setUploadOptionalDocumentDialog,
  ] = useState<Option<api.UploadedDocumentData>>(option.none);

  const [
    uploadNewRequiredDocumentDialog,
    setUploadNewRequiredDocumentDialog,
  ] = useState<Option<api.UploadedDocumentData>>(option.none);

  const {
    config: { r6Enabled },
  } = useAppContext();

  // const r6Enabled = true;

  usePollingEffect(api.checkUploadChanges, {
    intervalMS: 3000,
    disabled:
      props.isClientFlow &&
      pipe(
        requiredDocuments,
        remoteData.toOption,
        option.fold(constFalse, constTrue)
      ),
    shouldPollingContinue: constTrue,
    onError: constVoid,
    onSuccess: (result: api.CheckUploadChangesOutput) => {
      if (result.uploadChanges && option.isNone(uploadDialogDocument)) {
        refreshList();
      }

      if (result.uploadConfirmed) {
        props.onNext();
      }
    },
  });

  const onDocumentUpload = (file: File) => {
    function submit(fileContent: FileContent, fileType: DocumentMime) {
      return pipe(
        uploadDialogDocument,
        option.fold(
          () => taskEither.left("GenericError" as const),
          document =>
            pipe(
              uploadDocument({
                requiredDocumentMetadata: {
                  applicationElementID: document.metadata.applicationElementID,
                  clientID: document.metadata.clientID,
                  documentTypeID: document.metadata.documentTypeID,
                  documentTypeName: document.metadata.documentTypeName,
                  section: document.metadata.section,
                  translationCode: document.metadata.translationCode,
                },
                documentContent: fileContent,
                filename: file.name,
                mimeType: fileType,
              }),
              taskEither.mapLeft(() => "GenericError" as const)
            )
        )
      );
    }

    return pipe(
      checkFileSize(file.size),
      either.chainW(() => {
        return decodeFileType(file.type);
      }),
      taskEither.fromEither,
      taskEither.chainW(fileType =>
        pipe(
          encodeFileToBase64(file),
          taskEither.map(fileBase64 => ({ fileType, fileBase64 }))
        )
      ),
      taskEither.chainW(scope =>
        pipe(
          submit(scope.fileBase64, scope.fileType),
          taskEither.map(submitResult => ({ ...scope, ...submitResult }))
        )
      ),
      taskEither.chainW(scope =>
        taskEither.fromIOEither(() =>
          pipe(
            mapSubmitDocumentStatus(scope.status),
            either.map(() => scope)
          )
        )
      )
    );
  };

  const onDocumentConfirm = (note: Option<string>) =>
    pipe(
      confirmDocumentUpload({
        uploadNote: option.isSome(note) ? note.value : "",
      }),
      taskEither.mapLeft<unknown, DocumentNoteUploadError>(
        () => "GenericError"
      ),
      taskEither.chain(response =>
        taskEither.fromIOEither(() => mapSubmitNoteStatus(response.status))
      )
    );

  const onNewRequiredDocumentUpload = (file: File) => {
    function submit(fileContent: FileContent, fileType: DocumentMime) {
      return pipe(
        uploadNewRequiredDocumentDialog,
        option.fold(
          () => taskEither.left("GenericError" as const),
          document =>
            pipe(
              checkNewRequiredDocument({
                documentTypeId: document.metadata.documentTypeID,
                translationCode: document.metadata.translationCode,
                documentContent: fileContent,
                filename: file.name,
                mimeType: fileType,
                uniqueLfId: option.isSome(document.uniqueLfId)
                  ? document.uniqueLfId.value
                  : "",
              }),
              taskEither.mapLeft(() => "GenericError" as const)
            )
        )
      );
    }

    return pipe(
      checkFileSize(file.size),
      either.chainW(() => {
        return decodeFileType(file.type);
      }),
      taskEither.fromEither,
      taskEither.chainW(fileType =>
        pipe(
          encodeFileToBase64(file),
          taskEither.map(fileBase64 => ({ fileType, fileBase64 }))
        )
      ),
      taskEither.chainW(scope =>
        pipe(
          submit(scope.fileBase64, scope.fileType),
          taskEither.map(submitResult => ({ ...scope, ...submitResult }))
        )
      ),
      taskEither.chainW(scope =>
        taskEither.fromIOEither(() =>
          pipe(
            mapSubmitDocumentStatus(scope.status),
            either.map(() => scope)
          )
        )
      )
    );
  };

  const onOptionalDocumentUpload = (file: File) => {
    function submit(fileContent: FileContent, fileType: DocumentMime) {
      return pipe(
        uploadOptionalDocumentDialog,
        option.fold(
          () => taskEither.left("GenericError" as const),
          document =>
            pipe(
              checkOptionalDocument({
                documentTypeId: document.metadata.documentTypeID,
                oldDocId: option.isSome(document.oldDocId)
                  ? document.oldDocId.value
                  : "",
                documentContent: fileContent,
                filename: file.name,
                mimeType: fileType,
              }),
              taskEither.mapLeft(() => "GenericError" as const)
            )
        )
      );
    }

    return pipe(
      checkFileSize(file.size),
      either.chainW(() => {
        return decodeFileType(file.type);
      }),
      taskEither.fromEither,
      taskEither.chainW(fileType =>
        pipe(
          encodeFileToBase64(file),
          taskEither.map(fileBase64 => ({ fileType, fileBase64 }))
        )
      ),
      taskEither.chainW(scope =>
        pipe(
          submit(scope.fileBase64, scope.fileType),
          taskEither.map(submitResult => ({ ...scope, ...submitResult }))
        )
      ),
      taskEither.chainW(scope =>
        taskEither.fromIOEither(() =>
          pipe(
            mapSubmitDocumentStatus(scope.status),
            either.map(() => scope)
          )
        )
      )
    );
  };

  const onNewOptionalDocumentUpload = (file: File) => {
    function submit(fileContent: FileContent, fileType: DocumentMime) {
      return pipe(
        checkNewOptionalDocument({
          documentContent: fileContent,
          filename: file.name,
          mimeType: fileType,
        }),
        taskEither.mapLeft(() => "GenericError" as const)
      );
    }
    return pipe(
      checkFileSize(file.size),
      either.chainW(() => {
        return decodeFileType(file.type);
      }),
      taskEither.fromEither,
      taskEither.chainW(fileType =>
        pipe(
          encodeFileToBase64(file),
          taskEither.map(fileBase64 => ({ fileType, fileBase64 }))
        )
      ),
      taskEither.chainW(scope =>
        pipe(
          submit(scope.fileBase64, scope.fileType),
          taskEither.map(submitResult => ({ ...scope, ...submitResult }))
        )
      ),
      taskEither.chainW(scope =>
        taskEither.fromIOEither(() =>
          pipe(
            mapSubmitDocumentStatus(scope.status),
            either.map(() => scope)
          )
        )
      )
    );
  };

  const onOptionalDocumentConfirm = (note: Option<string>) =>
    pipe(
      confirmOptionalDocument({
        uploadNote: option.isSome(note) ? note.value : "",
      }),
      taskEither.mapLeft<unknown, DocumentNoteUploadError>(
        () => "GenericError"
      ),
      taskEither.chain(response =>
        taskEither.fromIOEither(() => mapSubmitNoteStatus(response.status))
      ),
      taskEither.chain(() =>
        taskEither.fromIO(() => {
          refreshList();
        })
      )
    );

  const onNewRequiredDocumentConfirm = (note: Option<string>) =>
    pipe(
      confirmNewRequiredDocument({
        uploadNote: option.isSome(note) ? note.value : "",
      }),
      taskEither.mapLeft<unknown, DocumentNoteUploadError>(
        () => "GenericError"
      ),
      taskEither.chain(response =>
        taskEither.fromIOEither(() => mapSubmitNoteStatus(response.status))
      ),
      taskEither.chain(() =>
        taskEither.fromIO(() => {
          refreshList();
        })
      )
    );

  const onDocumentCancel = () => {
    pipe(
      uploadDialogDocument,
      option.map(document =>
        pipe(
          cancelUpload({
            applicationElementID: document.metadata.applicationElementID,
            clientID: document.metadata.clientID,
            documentTypeID: document.metadata.documentTypeID,
            documentTypeName: document.metadata.documentTypeName,
            section: document.metadata.section,
            translationCode: document.metadata.translationCode,
          })()
        )
      )
    );
  };

  const renderRequiredDocuments = (
    documents: api.UploadedDocumentData[],
    section: api.SectionType
  ) => {
    return (
      <UploadDocumentsSection
        section={documents.filter(document => document.section === section)}
        sectionName={section}
        showMandatoryError={formSubmitted}
        onSelectDocument={document =>
          setUploadDialogDocument(option.some(document))
        }
        onRemoveDocument={docId =>
          pipe(
            removeDocument({ docId }),
            taskEither.map(() => {
              refreshList();
            })
          )()
        }
      />
    );
  };

  const renderOptionalSection = (
    documents: api.UploadedDocumentData[],
    section: api.SectionType
  ) => {
    return (
      <OptionalDocumentsSection
        section={documents.filter(document => document.section === section)}
        sectionName={section}
        showMandatoryError={formSubmitted}
        onSelectDocument={document =>
          setUploadOptionalDocumentDialog(option.some(document))
        }
        onRemoveDocument={docId =>
          pipe(
            removeOptionalDocument({ docId }),
            taskEither.map(() => {
              refreshList();
            })
          )()
        }
        onAddNewOptionalDocument={() =>
          setUploadNewOptionalDocumentDialog(true)
        }
      />
    );
  };

  const getDocumentsBySection = (
    documents: api.UploadedDocumentData[],
    section: api.SectionType
  ) => {
    return documents.filter(document => document.section === section);
  };

  const renderNewRequiredDocumentsSection = (
    documents: api.UploadedDocumentData[],
    section: api.SectionType
  ) => {
    return (
      <NewRequiredDocumentsSection
        section={documents.filter(document => document.section === section)}
        sectionName={section}
        showMandatoryError={formSubmitted}
        onSelectDocument={document =>
          setUploadNewRequiredDocumentDialog(option.some(document))
        }
        onRemoveDocument={docId =>
          pipe(
            removeNewRequiredDocument({ docId }),
            taskEither.map(() => {
              refreshList();
            })
          )()
        }
      />
    );
  };

  const renderSendUploadLink = (
    <ContentRow type="lateral-margins">
      <Body size="medium" weight="regular" align="left">
        {[
          formatMessage("StandardLoan.UploadDocuments.subTitle"),
          buttonLink(
            pipe(
              sendLinkToClient(),
              taskEither.chain(() =>
                taskEither.fromIO(() => {
                  showSentLinkConfirmation(true);
                  setIsLinkSent(true);
                })
              )
            ),
            pipe(
              isLinkSent,
              boolean.fold(
                () => formatMessage("StandardLoan.UploadDocuments.sendLink"),
                () => formatMessage("StandardLoan.UploadDocuments.resendLink")
              )
            )
          ),
        ]}
      </Body>
    </ContentRow>
  );

  function getFileSizeMax(): number {
    return 10;
  }

  function checkFileSize(fileSize: number) {
    return fileSize < getFileSizeMax() * 1024 * 1024
      ? either.right(undefined)
      : either.left("UnsupportedDocumentSize" as const);
  }

  const loadingError = () => (
    <ErrorBanner>
      {formatMessage("StandardLoan.UploadDocuments.loadingError")}
    </ErrorBanner>
  );

  const stepComments = useCommand(reworkApi.stepComments);

  const comments = useRemoteData(
    useMemo(() => stepComments({ reworkCategories: ["DOCUMENT"] }), [r6Enabled])
  );

  const reworkComments = pipe(
    comments,
    remoteData.fold(
      () => option.none,
      () => option.none,
      comments => option.some(comments)
    )
  );

  const areReworkComments =
    option.isSome(reworkComments) &&
    option.isSome(reworkComments.value.stepComments) &&
    reworkComments.value.stepComments.value.length > 0;

  const reworkBanner = (comments: Option<StepCommentsOutput>) => {
    return (
      <ContentRow type="lateral-margins">
        <ReworkBanner
          changes={option.none}
          stepComments={comments}
          hideChangesSection={true}
          forceDisplayComments
        />
      </ContentRow>
    );
  };

  return (
    <MainContent>
      <PageHeading
        title={formatMessage("StandardLoan.UploadDocuments.title")}
        hideOnMobile
      />

      {isMobileLayout && <Space units={8} />}

      {sentLinkConfirmation && (
        <FeedbackDialog
          type="success"
          title={formatMessage(
            "Mortgage.Dashboard.Documents.SendLinkDialog.SendLinkFeedbackDialog.title"
          )}
          subtitle={formatMessage(
            "Mortgage.Dashboard.Documents.SendLinkDialog.SendLinkFeedbackDialog.subtitle2"
          )}
          children={
            <Banner
              type="informative"
              actions={option.none}
              content={formatMessage(
                "Mortgage.Dashboard.Documents.SendLinkDialog.SendLinkFeedbackDialog.banner"
              )}
              title={option.none}
              onDismiss={option.none}
            />
          }
          cta={{
            label: formatMessage(
              "Mortgage.Dashboard.Documents.SendLinkDialog.SendLinkFeedbackDialog.buttonLabel"
            ),
            action: () => {
              showSentLinkConfirmation(false);
            },
          }}
        />
      )}
      <Stack column shrink units={8}>
        {!props.isClientFlow && renderSendUploadLink}
        {pipe(
          pipe(
            requiredDocuments,
            remoteData.toOption,
            option.fold(
              () => option.none,
              documents => option.some(documents)
            )
            /*option.map(documents => documents)*/
          ),
          option.fold(
            () => (
              <Box hAlignContent="center">
                <Loader />
              </Box>
            ),
            documents =>
              documents.length === 0 ? (
                props.onNext() && null
              ) : (
                <Stack units={8} column={true}>
                  {r6Enabled &&
                    areReworkComments &&
                    reworkBanner(reworkComments)}
                  <ContentRow type="lateral-margins">
                    <Card grow>
                      <Stack column grow units={7} style={{ flexBasis: 0 }}>
                        <Heading size="medium" weight="medium">
                          {formatMessage(
                            "StandardLoan.UploadDocuments.Panel.title"
                          )}
                        </Heading>
                        {pipe(
                          documents,
                          extractSectionsFromDocuments,
                          option.fold(loadingError, sections => (
                            <Stack column units={12} divider>
                              {pipe(
                                sections,
                                array.map(
                                  section =>
                                    section !== "OptionalDocuments" &&
                                    section !== "NewRequiredDocuments" &&
                                    renderRequiredDocuments(documents, section)
                                )
                              )}
                            </Stack>
                          ))
                        )}
                        {r6Enabled &&
                          getDocumentsBySection(
                            documents,
                            "NewRequiredDocuments"
                          ).length > 0 &&
                          renderNewRequiredDocumentsSection(
                            documents,
                            "NewRequiredDocuments"
                          )}
                        {r6Enabled &&
                          renderOptionalSection(documents, "OptionalDocuments")}
                      </Stack>
                    </Card>
                  </ContentRow>

                  {formSubmitted &&
                    documents.filter(({ docId }) => option.isNone(docId))
                      .length > 0 && (
                      <ContentRow type="lateral-margins">
                        <Banner
                          actions={option.none}
                          type="error"
                          content={formatMessage(
                            "StandardLoan.UploadDocuments.error"
                          )}
                          title={option.none}
                        />
                      </ContentRow>
                    )}

                  <ContentRow type="lateral-margins">
                    <Box grow column={isMobileLayout} hAlignContent="right">
                      <LoadingButton
                        variant="primary"
                        type="submit"
                        labels={{
                          normal: formatMessage("Confirm.buttonLabel"),
                          success: formatMessage("Confirm.buttonLabel"),
                          error: formatMessage("Confirm.buttonLabel"),
                          loading: formatMessage("Confirm.buttonLabel"),
                        }}
                        size="default"
                        action={pipe(
                          documents,
                          array.filter(({ docId }) => option.isNone(docId)),
                          emptyDocs => emptyDocs.length === 0,
                          boolean.fold(
                            () =>
                              pipe(
                                taskEither.leftIO(() => {
                                  setFormSubmitted(true);
                                })
                              ),
                            () =>
                              pipe(
                                confirmUpload(),
                                taskEither.chain(() => props.onNext)
                              )
                          )
                        )}
                      />
                    </Box>
                  </ContentRow>
                </Stack>
              )
          )
        )}
      </Stack>
      {pipe(
        uploadDialogDocument,
        option.map(document => (
          <StandardLoanDocumentUploadDialog
            onDismiss={() => {
              setUploadDialogDocument(option.none);
              refreshList();
            }}
            onDocumentUpload={onDocumentUpload}
            maxLengthNote={option.none}
            onDocumentConfirm={onDocumentConfirm}
            onDocumentCancel={onDocumentCancel}
            selectedApplicant={selectedMainApplicant}
            skipNotes={false}
            acceptableFileTypes={fileTypes}
            docTypeId={document.metadata.documentTypeID}
            applicationElementID={option.some(
              document.metadata.applicationElementID
            )}
            fileSizeMax={getFileSizeMax()}
            productType={props.productType}
            documentName={document.documentName}
          />
        )),
        option.toUndefined
      )}
      {pipe(
        uploadNewRequiredDocumentDialog,
        option.map(document => (
          <OptionalDocumentUploadDialog
            onDismiss={() => {
              setUploadNewRequiredDocumentDialog(option.none);
              refreshList();
            }}
            onDocumentUpload={onNewRequiredDocumentUpload}
            maxLengthNote={option.none}
            onDocumentConfirm={onNewRequiredDocumentConfirm}
            onDocumentCancel={() => pipe(cancelNewRequiredDocument()())}
            selectedApplicant={selectedMainApplicant}
            skipNotes={false}
            acceptableFileTypes={fileTypes}
            applicationElementID={option.none}
            fileSizeMax={getFileSizeMax()}
            productType={props.productType}
            documentName={document.documentName}
          />
        )),
        option.toUndefined
      )}
      {pipe(
        uploadOptionalDocumentDialog,
        option.map(document => (
          <OptionalDocumentUploadDialog
            onDismiss={() => {
              setUploadOptionalDocumentDialog(option.none);
              refreshList();
            }}
            onDocumentUpload={onOptionalDocumentUpload}
            maxLengthNote={option.none}
            onDocumentConfirm={onOptionalDocumentConfirm}
            onDocumentCancel={() => pipe(cancelOptionalDocument()())}
            selectedApplicant={selectedMainApplicant}
            skipNotes={false}
            acceptableFileTypes={fileTypes}
            applicationElementID={option.none}
            fileSizeMax={getFileSizeMax()}
            productType={props.productType}
            documentName={document.documentName}
          />
        )),
        option.toUndefined
      )}

      {uploadNewOptionalDocumentDialog && (
        <OptionalDocumentUploadDialog
          onDismiss={() => {
            setUploadNewOptionalDocumentDialog(false);
            refreshList();
          }}
          onDocumentUpload={onNewOptionalDocumentUpload}
          maxLengthNote={option.none}
          onDocumentConfirm={onOptionalDocumentConfirm}
          onDocumentCancel={() => pipe(cancelOptionalDocument()())}
          selectedApplicant={selectedMainApplicant}
          skipNotes={false}
          acceptableFileTypes={fileTypes}
          applicationElementID={option.none}
          fileSizeMax={getFileSizeMax()}
          productType={props.productType}
        />
      )}
    </MainContent>
  );
}

function extractSectionsFromDocuments(
  documents: api.UploadedDocumentData[]
): Option<NonEmptyArray<api.SectionType>> {
  const sections: api.SectionType[] = [];

  documents.forEach(
    document =>
      !sections.includes(document.section) && sections.push(document.section)
  );

  return nonEmptyArray.fromArray(sections);
}
