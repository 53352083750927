import { useEffect, useState } from "react";
import { option, taskEither } from "fp-ts";
import moment from "moment";
import { getCoreCalendar } from "./api";
import { useCommand } from "../useAPI";
import { pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { IO } from "fp-ts/IO";
import { Reader } from "fp-ts/Reader";

type Status = "Loading" | "Ready";
type DayType = "Working" | "NonWorking" | "None";
export type DayTypeInfo = {
  status: Status;
  dayType: Option<DayType>;
  isWorkingDay: boolean;
};

export const useCheckDay = () => {
  const [dayTypeInfo, setDayTypeInfo] = useState<DayTypeInfo>({
    dayType: option.none,
    isWorkingDay: false,
    status: "Loading",
  });
  const getCalendar = useCommand(getCoreCalendar);

  const checkCurrentDay = () => {
    const today = moment();

    const day = parseInt(today.format("D"));
    const month = parseInt(today.format("M"));
    const year = parseInt(today.format("YYYY"));

    pipe(
      getCalendar(),
      taskEither.chain(result =>
        taskEither.fromIO(() => {
          const currentMonthInfo = result.find(
            value => value.month === month && value.year === year
          );
          const dayToCheck = currentMonthInfo
            ? parseInt(currentMonthInfo.days[day - 1])
            : 0;

          switch (dayToCheck) {
            case 0:
              return setDayTypeInfo({
                dayType: option.some("None"),
                isWorkingDay: false,
                status: "Ready",
              });
            case 1:
              return setDayTypeInfo({
                dayType: option.some("Working"),
                isWorkingDay: true,
                status: "Ready",
              });
            case 2:
              return setDayTypeInfo({
                dayType: option.some("NonWorking"),
                isWorkingDay: false,
                status: "Ready",
              });
          }
        })
      )
    )();
  };

  useEffect(() => checkCurrentDay(), []);

  function foldStatus<T>(matches: {
    whenLoading: IO<T>;
    whenReady: Reader<DayTypeInfo, T>;
  }) {
    switch (dayTypeInfo.status) {
      case "Loading":
        return matches.whenLoading();
      case "Ready":
        return matches.whenReady(dayTypeInfo);
    }
  }

  return { foldStatus, dayTypeInfo, refresh: checkCurrentDay };
};
