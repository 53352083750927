import { Box, Button, Heading, PlusIcon, Stack } from "design-system";
import * as api from "../api";
import { LocaleKey, useFormatMessage } from "../../intl";
import { NonEmptyString } from "io-ts-types/NonEmptyString";
import { UploadDocumentItem } from "./UploadDocumentItem";
import { DocumentNote } from "./DocumentNote";
import { option } from "fp-ts";

type Props = {
  section: api.UploadedDocumentData[];
  sectionName: api.SectionType;
  showMandatoryError: boolean;
  onSelectDocument: (document: api.UploadedDocumentData) => void;
  onRemoveDocument: (docId: NonEmptyString) => void;
  onAddNewOptionalDocument: () => void;
};

export function OptionalDocumentsSection(props: Props) {
  const formatMessage = useFormatMessage();

  const renderAddOptionalDocument = (
    <Box>
      <Button
        variant="text"
        label={formatMessage(
          "Mortgage.Documents.accordion.button.addOptionalDocuments"
        )}
        icon={PlusIcon}
        size="default"
        action={props.onAddNewOptionalDocument}
      />
    </Box>
  );

  const renderDocumentItemAndNote = (document: api.UploadedDocumentData) => {
    return (
      <>
        <UploadDocumentItem
          document={document}
          showMandatoryError={props.showMandatoryError}
          onSelectItem={() => props.onSelectDocument(document)}
          onRemoveItem={props.onRemoveDocument}
        />
        {option.isSome(document.uploadNote) &&
          document.uploadNote.value.length > 0 && (
            <DocumentNote note={document.uploadNote.value} />
          )}
      </>
    );
  };

  return (
    <Stack grow column units={2}>
      <Heading size="x-small" weight="medium">
        {formatMessage(getSectionLabel(props.sectionName))}
      </Heading>
      <Stack column grow units={10}>
        {props.section.map(document => renderDocumentItemAndNote(document))}
        {
          props.section.length < 5 && renderAddOptionalDocument // TODO Take the limit from BFF, with /requiredDocuments response, in R6
        }
      </Stack>
    </Stack>
  );
}

function getSectionLabel(section: api.SectionType): LocaleKey {
  switch (section) {
    case "MainIncome":
      return "StandardLoan.UploadDocuments.MainIncome.title";
    case "AdditionalIncome":
      return "StandardLoan.UploadDocuments.AdditionalIncome.title";
    case "Refinancing":
      return "StandardLoan.UploadDocuments.Refinancing.title";
    case "OptionalDocuments":
      return "StandardLoan.UploadDocuments.OptionalDocuments.title";
    case "NewRequiredDocuments":
      return "StandardLoan.UploadDocuments.NewRequiredDocuments.title";
  }
}
