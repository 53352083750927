import { Heading, Stack } from "design-system";
import * as api from "../api";
import { LocaleKey, useFormatMessage } from "../../intl";
import { NonEmptyString } from "io-ts-types/NonEmptyString";
import { UploadDocumentItem } from "./UploadDocumentItem";
import { option } from "fp-ts";
import { DocumentNote } from "./DocumentNote";

type Props = {
  section: api.UploadedDocumentData[];
  sectionName: api.SectionType;
  showMandatoryError: boolean;
  onSelectDocument: (document: api.UploadedDocumentData) => void;
  onRemoveDocument: (docId: NonEmptyString) => void;
};

export function NewRequiredDocumentsSection(props: Props) {
  const formatMessage = useFormatMessage();

  const renderDocumentItemAndNote = (document: api.UploadedDocumentData) => {
    return (
      <>
        <UploadDocumentItem
          document={document}
          showMandatoryError={props.showMandatoryError}
          onSelectItem={() => props.onSelectDocument(document)}
          onRemoveItem={props.onRemoveDocument}
        />
        {option.isSome(document.uploadNote) &&
          document.uploadNote.value.length > 0 && (
            <DocumentNote note={document.uploadNote.value} />
          )}
      </>
    );
  };

  return (
    <Stack grow column units={2}>
      <Heading size="x-small" weight="medium">
        {formatMessage(getSectionLabel(props.sectionName))}
      </Heading>
      <Stack column grow units={10}>
        {props.section.map(document => renderDocumentItemAndNote(document))}
      </Stack>
    </Stack>
  );
}

function getSectionLabel(section: api.SectionType): LocaleKey {
  switch (section) {
    case "MainIncome":
      return "StandardLoan.UploadDocuments.MainIncome.title";
    case "AdditionalIncome":
      return "StandardLoan.UploadDocuments.AdditionalIncome.title";
    case "Refinancing":
      return "StandardLoan.UploadDocuments.Refinancing.title";
    case "OptionalDocuments":
      return "StandardLoan.UploadDocuments.OptionalDocuments.title";
    case "NewRequiredDocuments":
      return "StandardLoan.UploadDocuments.NewRequiredDocuments.title";
  }
}
