import { AppProvider } from "./AppProvider";
import { AuthenticationContextProvider } from "./AuthenticationContext";
import { ThirdPartyAuth } from "./3PLogin/ThirdPartyAuth";
import { EnvBadge } from "./Common/EnvBadge/EnvBadge";
import { AuthenticationInfo } from "design-system";
import { option, taskEither } from "fp-ts";
import { Option } from "fp-ts/Option";

type Props = {
  authInfo: Option<AuthenticationInfo>;
  showPasswordWasResetMessage: boolean;
  children: (passwordWasChanged: boolean) => JSX.Element;
};

export function ThirdPartyAppProvider(props: Props) {
  return (
    <AppProvider {...props} channel="3P_InPerson">
      <AuthenticationContextProvider
        is3P
        authInfo={props.authInfo}
        potentialClientToken={taskEither.of(option.none)}
      >
        <ThirdPartyAuth
          showPasswordWasResetMessage={props.showPasswordWasResetMessage}
          render={passwordWasChanged => props.children(passwordWasChanged)}
        />
        <EnvBadge />
      </AuthenticationContextProvider>
    </AppProvider>
  );
}
