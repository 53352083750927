import { Option } from "fp-ts/Option";
import {
  ComponentProps,
  Dispatch,
  useEffect,
  useState,
  SetStateAction,
} from "react";
import { LocalizedString } from "design-system";
import { option, array } from "fp-ts";
import * as zipper from "fp-ts-contrib/Zipper";
import {
  accountsForRefinancingAction,
  goToStepAction,
  State,
  Action,
} from "./StandardLoanState";
import { useFormatMessage } from "../intl";
import { useFlowTypeCases } from "./useFlowTypeCases";
import { StandardLoanFlowType } from "./domain";
import { identity, pipe } from "fp-ts/function";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { Stepper } from "../Common/MainContent/Stepper";
import { useAppContext } from "../useAppContext";

type Props = {
  flowType: StandardLoanFlowType;
  state: State;
  dispatch: Dispatch<any>;
  stepVisibility: Option<NonEmptyArray<StepDefinition["name"]>>;
  isRework: boolean;
  isExistingClient: boolean;
};

interface StepDefinition {
  name: State["type"];
  action: () => void;
  label: LocalizedString;
  visible: boolean;
}

export const useSteps = (props: Props) => {
  const formatMessage = useFormatMessage();
  const { isPWSRemote, isTLSorPWSRemoteAndCZ } = useFlowTypeCases(
    props.flowType
  );

  const {
    config: { enableVirtualCardsLoanImpl },
  } = useAppContext();

  const defaultFixedStepDefinition: StepDefinition[] = [
    {
      name: "PersonalData",
      action: () => props.dispatch(goToStepAction("PersonalData")),
      label: formatMessage("Identification.personalData.personalData"),
      visible: props.isRework,
    },
    {
      name: "PhoneAndEmailVerification",
      action: () => props.dispatch(goToStepAction("VerifyPhoneAndEmail")),
      label: formatMessage("StandardLoan.Steps.step1"),
      visible: true,
    },
    {
      name: "UploadID",
      action: () => props.dispatch(goToStepAction("UploadID")),
      label: formatMessage("StandardLoan.Steps.step2"),
      visible: true,
    },
    {
      name: "CreditChecks",
      action: () => props.dispatch(goToStepAction("CreditChecks")),
      label: formatMessage("StandardLoan.Steps.step3"),
      visible: true,
    },
    {
      name: "CustomerOffer",
      action: () => props.dispatch(goToStepAction("CustomerOffer")),
      label: formatMessage("StandardLoan.Steps.step4"),
      visible: true,
    },
    {
      name: "ExpensesAndAdditionalIncome",
      action: () =>
        props.dispatch(goToStepAction("ExpensesAndAdditionalIncome")),
      label: formatMessage("StandardLoan.Steps.step5"),
      visible: true,
    },
    {
      name: "OfferList",
      action: () => props.dispatch(goToStepAction("OfferList")),
      label: formatMessage("StandardLoan.Steps.step6"),
      visible: true,
    },
    {
      name: "OfferReview",
      action: () => {
        switch (props.state.type) {
          case "Loading":
          case "Landing":
          case "PhoneAndEmailVerification":
          case "UploadID":
          case "CreditChecks":
          case "ExistingClient":
          case "ExistingApplication3PError":
          case "RestoreApplication":
          case "CustomerOffer":
          case "AccountsForRefinancing":
          case "Rejected":
          case "Summary":
          case "UploadDocuments":
            return;
          case "ExpensesAndAdditionalIncome":
          case "OfferList":
          case "OfferReview":
          case "KYC":
          case "Micropayment":
          case "FollowUpAndSignature":
            if (props.state.payload.hasRefinancingCredits) {
              props.dispatch(accountsForRefinancingAction(true));
            }
            return;
        }
      },
      label: formatMessage("StandardLoan.Steps.step7"),
      visible: true,
    },
    {
      name: "PackageSelection",
      action: () => props.dispatch(goToStepAction("PackageSelection")),
      label: formatMessage("StandardLoan.Steps.step7.1"),
      visible: enableVirtualCardsLoanImpl && !props.isExistingClient,
    },
    {
      name: "VirtualCard",
      action: () => props.dispatch(goToStepAction("VirtualCard")),
      label: formatMessage("StandardLoan.Steps.step7.2"),
      visible: enableVirtualCardsLoanImpl && !props.isExistingClient,
    },
    {
      name: "AccountsForRefinancing",
      action: () => props.dispatch(goToStepAction("AccountsForRefinancing")),
      label: formatMessage("StandardLoan.Steps.step8"),
      visible: false,
    },
    {
      name: "KYC",
      action: () => props.dispatch(goToStepAction("KYC")),
      label: formatMessage("StandardLoan.Steps.step9"),
      visible: true,
    },
    {
      name: "Micropayment",
      action: () => props.dispatch(goToStepAction("Micropayment")),
      label: formatMessage("StandardLoan.Steps.step9.1"),
      visible: isTLSorPWSRemoteAndCZ,
    },
  ];
  const defaultNonFixedStepDefinition: StepDefinition[] = pipe<
    StepDefinition[],
    StepDefinition[]
  >(
    [
      {
        name: "FollowUpAndSignature",
        action: () => props.dispatch(goToStepAction("FollowUpAndSignature")),
        label: formatMessage("StandardLoan.Steps.step12"),
        visible: true,
      },
      {
        name: "UploadDocuments",
        action: () => props.dispatch(goToStepAction("UploadDocuments")),
        label: formatMessage("StandardLoan.Steps.step11"),
        visible: isTLSorPWSRemoteAndCZ || isPWSRemote,
      },
    ],
    props.isRework ? array.reverse : identity
  );

  const [stepsDefinition, setStepDefinition] = useState([
    ...defaultFixedStepDefinition,
    ...defaultNonFixedStepDefinition,
  ]);

  useEffect(() => {
    const setStepVisibility = (
      stepName: State["type"],
      visibility: boolean,
      stepsDefinition: StepDefinition[]
    ) =>
      stepsDefinition.map(step =>
        step.name === stepName ? { ...step, visible: visibility } : step
      );

    const foldStepDefinition = () => {
      switch (props.state.type) {
        case "OfferReview":
        case "AccountsForRefinancing":
        case "KYC":
          return setStepVisibility(
            "AccountsForRefinancing",
            props.state.payload.hasRefinancingCredits,
            stepsDefinition
          );
        case "FollowUpAndSignature":
          const foldDefinition = setStepVisibility(
            "AccountsForRefinancing",
            props.state.payload.hasRefinancingCredits,
            stepsDefinition
          );
          return setStepVisibility(
            "KYC",
            props.state.payload.isKycNeeded,
            foldDefinition
          );
        default:
          return stepsDefinition;
      }
    };

    const stepDefinitionByVisibilityArray = (
      stepVisibility: NonEmptyArray<StepDefinition["name"]>
    ): StepDefinition[] =>
      stepsDefinition.map(step => ({
        ...step,
        visible: stepVisibility.includes(step.name),
      }));

    pipe(
      props.stepVisibility,
      option.fold(
        () => setStepDefinition(foldStepDefinition()),
        steps => setStepDefinition(stepDefinitionByVisibilityArray(steps))
      )
    );
  }, [props.state.type]);

  function getSteps(): Option<ComponentProps<typeof Stepper>["steps"]> {
    function makeZipper(
      currentStepName: State["type"]
    ): ComponentProps<typeof Stepper>["steps"] {
      const visibleSteps = stepsDefinition.filter(step => step.visible);
      const currentStepIndex = visibleSteps.findIndex(
        step => step.name === currentStepName
      );
      const steps = visibleSteps.map(step => step.label);
      return zipper.make(
        currentStepIndex > 0 ? steps.slice(0, currentStepIndex) : [],
        steps[currentStepIndex],
        steps.slice(currentStepIndex + 1)
      );
    }

    const currentStepOrEquivalent =
      props.state.type === "CheckExpensesOffers" ||
      props.state.type === "SendAdditionalIncome"
        ? "ExpensesAndAdditionalIncome"
        : props.state.type;

    return pipe(
      stepsDefinition,
      array.findFirst(
        step => step.visible && step.name === currentStepOrEquivalent
      ),
      option.map(({ name }) => makeZipper(name))
    );
  }

  const goToStep = (
    stepIndex: number
  ): Dispatch<SetStateAction<Action>> | void =>
    stepsDefinition.filter(step => step.visible)[stepIndex].action();

  return { getSteps, goToStep };
};
