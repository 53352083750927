import {
  Box,
  CloseIcon,
  ContentRow,
  Dialog,
  useIsMobileLayout,
} from "design-system";
import { boolean, option, readerTaskEither, taskEither } from "fp-ts";
import { constant, constVoid, flow, pipe } from "fp-ts/function";
import {
  ExistingClientAuthenticationMethod,
  genericError,
} from "../../globalDomain";
import { useCommand } from "../../useAPI";
import { IdUpload } from "../../IdUpload/IdUpload";
import * as api from "../api";
import { useClientExistencyCheck } from "../../UploadDocuments/clientExistenceUtils";
import * as uploadApi from "../../IdUpload/api";
import { BackButton } from "../../Common/BackButton/BackButton";
import { IO } from "fp-ts/IO";
import { RefuseExistingClientReason, StandardLoanFlowType } from "../domain";
import { Option } from "fp-ts/Option";
import { useFlowTypeCases } from "../useFlowTypeCases";
import { useState } from "react";
import { useFormatMessage } from "../../intl";
import { useIsInPersonChannel } from "../../useChannel";

type Props = {
  onComplete: () => unknown;
  onRefuse: (reason: RefuseExistingClientReason) => void;
  onFailure: (
    reason: "GenericError" | "MaxAttemptsReached" | "ValidationError"
  ) => unknown;
  onBack: IO<unknown>;
  authenticationMethod: Option<ExistingClientAuthenticationMethod>;
  flowType: StandardLoanFlowType;
  isThirdParty: boolean;
};

export function UploadId(props: Props) {
  const clientExistenceCommand = useCommand(api.checkClientExisting);
  const formatMessage = useFormatMessage();
  const [isBelow18, setIsBelow18] = useState(false);
  const clientExistenceCheck = useClientExistencyCheck(
    clientExistenceCommand,
    () => genericError
  );
  const openClient = useCommand(api.openClient);

  const personalInfoSubmit = useCommand(api.personalInfoSubmit);
  const editData = useCommand(uploadApi.personalInfoEdit);
  const isMobileLayout = useIsMobileLayout();

  const {
    isPWSRemote,
    isTLS,
    isTLSorPWSRemoteAndCZ,
    isTLSorPWSRemoteAndSK,
  } = useFlowTypeCases(props.flowType);
  const isInPersonChannel = useIsInPersonChannel();
  const isTLSorPWSremoteExistingClient =
    (isPWSRemote || isTLS) && option.isSome(props.authenticationMethod);

  const documentIdentificationFlow = isTLSorPWSremoteExistingClient
    ? "Primary"
    : isTLSorPWSRemoteAndCZ
    ? "PrimaryAndSecondary"
    : isTLSorPWSRemoteAndSK
    ? "PrimaryAndLivenessCheck"
    : "Primary";

  const extractData = useCommand(api.extractClientData)({
    idType:
      documentIdentificationFlow === "PrimaryAndSecondary"
        ? option.none
        : option.some("Primary"),
    coApplicant: option.none,
  });

  const rejectData = useCommand(uploadApi.rejectData)({
    idType:
      documentIdentificationFlow === "PrimaryAndSecondary"
        ? option.none
        : option.some("Primary"),
    coApplicant: option.none,
  });

  const checkBelow18 = (dateOfBirth: Date) => {
    const age = new Date().getFullYear() - dateOfBirth.getFullYear();

    return age < 18;
  };

  return (
    <Box column grow shrink>
      <IdUpload
        productType={option.none}
        coApplicant={option.none}
        onComplete={flow(
          taskEither.fromOption(constant(genericError)),
          taskEither.chain(clientExistenceCheck),
          taskEither.mapLeft(() => genericError),
          taskEither.chain(clientData =>
            pipe(
              clientData.clientExistencyCheckData.dateOfBirth,
              checkBelow18,
              boolean.fold(
                () =>
                  !clientData.clientExists && clientData.personalNumberMatch
                    ? taskEither.fromIO(() =>
                        props.onRefuse("IsPersonalNumberMatch")
                      )
                    : pipe(
                        clientData.clientExists,
                        boolean.fold(
                          () =>
                            pipe(
                              openClient(),
                              taskEither.mapLeft(constant(genericError)),
                              taskEither.chain(() =>
                                taskEither.fromIO(() => props.onComplete())
                              )
                            ),
                          () =>
                            taskEither.fromIO(() => {
                              if (props.isThirdParty) {
                                return props.onComplete();
                              }
                              return pipe(
                                clientData.userID,
                                option.fold(
                                  () =>
                                    props.onRefuse(
                                      clientData.multipleUsers
                                        ? "IsMultipleUser"
                                        : "IsExisting"
                                    ),
                                  () => props.onRefuse("HasUserId")
                                )
                              );
                            })
                        )
                      ),
                () => taskEither.fromIO(() => setIsBelow18(true))
              )
            )
          )
        )}
        onFailure={props.onFailure}
        onShouldClientContinue={option.none}
        documentIdentificationFlow={documentIdentificationFlow}
        extractData={extractData}
        clientExistencyCommand={clientExistenceCommand}
        rejectData={rejectData}
        submitConfirmedDataCommand={confirmedData =>
          pipe({ idType: confirmedData.idType }, personalInfoSubmit)
        }
        documentsMismatch={false}
        onMismatchDialogDismiss={constVoid}
        showMismatchDialog={constVoid}
        onReset={constVoid}
        isThirdParty={props.isThirdParty}
        checkOpuAndCex={!props.isThirdParty && !isTLS && isInPersonChannel}
        editPersonalInfoCommand={editData}
        submitResidencyCommand={readerTaskEither.fromIO(constVoid)}
      />
      <ContentRow type="lateral-margins">
        <Box grow column={isMobileLayout}>
          <BackButton action={props.onBack} />
        </Box>
      </ContentRow>
      {isBelow18 && (
        <Dialog
          variant="center"
          size="medium"
          onDismiss={option.none}
          icon={CloseIcon}
          title={formatMessage("StandardLoan.UploadDocuments.below18.title")}
          subtitle={formatMessage(
            "StandardLoan.UploadDocuments.below18.description"
          )}
          actions={[
            {
              action: props.onBack,
              label: formatMessage(
                "StandardLoan.UploadDocuments.below18.close"
              ),
              variant: "secondary",
            },
          ]}
        />
      )}
    </Box>
  );
}
