import { useAppContext } from "../useAppContext";
import { StandardLoanFlowType } from "./domain";

export const useFlowTypeCases = (flowType: StandardLoanFlowType) => {
  const {
    apiParameters: { tenant },
  } = useAppContext();

  const isPWSRemote = flowType === "PWSRemote";
  const isTLS = flowType === "TLSAgent";
  const isInPerson = flowType === "InPerson";
  const is3P = flowType === "3P";
  const isTLSorPWSRemoteAndCZ = tenant === "CZ" && (isPWSRemote || isTLS);
  const isTLSorPWSRemoteAndSK = tenant === "SK" && (isPWSRemote || isTLS);

  return {
    isPWSRemote,
    isTLS,
    is3P,
    isInPerson,
    isTLSorPWSRemoteAndCZ,
    isTLSorPWSRemoteAndSK,
  };
};
