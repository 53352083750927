import {
  Banner,
  Body,
  Box,
  buttonLink,
  Card,
  ContentRow,
  ErrorBanner,
  FileContent,
  Heading,
  Loader,
  LoadingButton,
  Stack,
  useIsMobileLayout,
  Space,
  FeedbackDialog,
} from "design-system";
import { constNull, constVoid, pipe } from "fp-ts/function";
import * as api from "../api";
import { PageHeading } from "../../Common/PageHeading/PageHeading";
import { useFormatMessage } from "../../intl";
import { useCommand, usePollingEffect, useQuery } from "../../useAPI";
import { UploadDocumentsSection } from "./UploadDocumentsSection";
import { TaskEither } from "fp-ts/TaskEither";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import {
  nonEmptyArray,
  option,
  array,
  taskEither,
  boolean,
  either,
} from "fp-ts";
import { useState } from "react";
import {
  decodeFileType,
  encodeFileToBase64,
  fileTypes,
  mapSubmitDocumentStatus,
  mapSubmitNoteStatus,
} from "../../MortgageDashboard/Documents/domain";
import { selectedMainApplicant } from "../../MortgageDashboard/mortgageDashboardUtils";
import { Option } from "fp-ts/Option";
import { DocumentMime } from "../../Common/documentAPI";
import { UUID } from "io-ts-types/lib/UUID";
import * as remoteData from "../../RemoteData";
import { StandardLoanDocumentUploadDialog } from "./StandardLoanDocumentUploadDialog";
import { UploadDocumentFlowType } from "../../globalDomain";
import { IO } from "fp-ts/IO";
import { UploadDocumentsRework } from "./UploadDocumentsRework";
import { OptionalDocumentUploadDialog } from "./OptionalDocumentUploadDialog";
import { OptionalDocumentsSection } from "./OptionalDocumentsSection";
import { DocumentNoteUploadError } from "../../Common/Dialogs/DocumentUploadDialog/domain";
import { useAppContext } from "../../useAppContext";
import { MainContent } from "../../Common/MainContent";
import { checkRequiredDocuments } from "../api";
import { BasicFullScreenLoader } from "../../Common/BasicFullScreenLoader";

type Props = {
  isClientFlow: boolean;
  bankerFlowId: Option<UUID>;
  onNext: TaskEither<unknown, unknown>;
  productType: Option<UploadDocumentFlowType>;
  onApplicationRejected: IO<unknown>;
  isRework?: boolean;
  requiredDocumentsUpToDate?: boolean;
  onNextWithoutConfirm: TaskEither<unknown, unknown>;
};

export function UploadRequiredDocuments(props: Props) {
  if (props.isRework) {
    return (
      <UploadDocumentsRework
        {...props}
        requiredDocumentsUpToDate={props.requiredDocumentsUpToDate === true}
        onNext={props.onNextWithoutConfirm}
      />
    );
  } else {
    return <UploadDocumentsNotReworkWithCheck {...props} />;
  }
}

function UploadDocumentsNotReworkWithCheck(props: Props) {
  const formatMessage = useFormatMessage();
  const [requiredDocumentsAvailable] = useQuery(checkRequiredDocuments);

  return pipe(
    requiredDocumentsAvailable,
    remoteData.fold(
      () => <BasicFullScreenLoader />,
      constNull,
      ({ requiredDocumentsReceived }) =>
        pipe(
          requiredDocumentsReceived,
          boolean.fold(
            () => (
              <ErrorBanner
                children={formatMessage(
                  "StandardLoan.UploadDocuments.requiredDocuments.notReady"
                )}
              />
            ),
            () => <UploadDocumentsNotRework {...props} />
          )
        )
    )
  );
}

function UploadDocumentsNotRework(props: Props) {
  const [requiredDocuments, refreshList] = useQuery(api.requiredDocuments);
  return pipe(
    requiredDocuments,
    remoteData.toOption,
    option.map(documents => (
      <UploadDocuments
        {...props}
        refreshList={refreshList}
        requiredDocuments={option.some(documents)}
        onApplicationRejected={props.onApplicationRejected}
      />
    )),
    option.toNullable
  );
}

type UploadDocumentsProps = Props & {
  requiredDocuments: Option<api.UploadedDocumentData[]>;
  refreshList: () => void;
  productType: Option<UploadDocumentFlowType>;
  onApplicationRejected: IO<unknown>;
};

export function UploadDocuments(props: UploadDocumentsProps) {
  const formatMessage = useFormatMessage();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const isMobileLayout = useIsMobileLayout();
  const removeDocument = useCommand(
    props.isClientFlow ? api.removeDocumentByClient : api.removeDocument
  );
  const cancelUpload = useCommand(
    props.isClientFlow ? api.cancelUploadByClient : api.cancelUpload
  );
  const uploadDocument = useCommand(
    props.isClientFlow ? api.uploadDocumentByClient : api.uploadDocument
  );
  const confirmDocumentUpload = useCommand(
    props.isClientFlow ? api.confirmDocumentByClient : api.confirmDocument
  );
  const checkNewOptionalDocument = useCommand(
    props.isClientFlow
      ? api.checkNewOptionalDocumentByClient
      : api.checkNewOptionalDocument
  );
  const confirmOptionalDocument = useCommand(
    props.isClientFlow
      ? api.confirmOptionalDocumentUploadByClient
      : api.confirmOptionalDocumentUpload
  );
  const cancelOptionalDocument = useCommand(
    props.isClientFlow
      ? api.cancelOptionalDocUploadByClient
      : api.cancelOptionalDocUpload
  );
  const removeOptionalDocument = useCommand(
    props.isClientFlow ? api.removeOptionalDocByClient : api.removeOptionalDoc
  );
  const sendLinkToClient = useCommand(api.sendLinkToClient);

  const getIsCBRejected = useCommand(api.getIsCBRejected);

  const [isLinkSent, setIsLinkSent] = useState<boolean>(false);
  const [sentLinkConfirmation, showSentLinkConfirmation] = useState<boolean>(
    false
  );
  const [uploadDialogDocument, setUploadDialogDocument] = useState<
    Option<api.UploadedDocumentData>
  >(option.none);

  const [
    uploadNewOptionalDocumentDialog,
    setUploadNewOptionalDocumentDialog,
  ] = useState<boolean>(false);

  const {
    config: { r6Enabled },
  } = useAppContext();

  // const r6Enabled = true;

  const submitWithRejectionCheck = () => {
    pipe(
      getIsCBRejected(),
      taskEither.chain(({ cbRejected }) => {
        return taskEither.fromIO(() =>
          pipe(
            cbRejected,
            boolean.fold(
              () => props.onNext(),
              () => props.onApplicationRejected()
            )
          )
        );
      })
    )();
  };

  const submitOnPolling = () => {
    pipe(
      getIsCBRejected(),
      taskEither.chain(({ cbRejected }) => {
        return taskEither.fromIO(() =>
          pipe(
            cbRejected,
            boolean.fold(
              () => props.onNextWithoutConfirm(),
              () => props.onApplicationRejected()
            )
          )
        );
      })
    )();
  };

  usePollingEffect(api.checkUploadChanges, {
    intervalMS: 3000,
    disabled: props.isClientFlow,
    shouldPollingContinue: (result: api.CheckUploadChangesOutput) =>
      !result.uploadConfirmed,
    onError: constVoid,
    onSuccess: (result: api.CheckUploadChangesOutput) => {
      if (result.uploadChanges && option.isNone(uploadDialogDocument)) {
        props.refreshList();
      }
      if (result.uploadConfirmed) {
        submitOnPolling();
      }
    },
  });

  const dispatchWithRejectionCheck = (
    document: Option<api.UploadedDocumentData>
  ) => {
    pipe(
      getIsCBRejected(),
      taskEither.chain(({ cbRejected }) => {
        return taskEither.fromIO(() =>
          pipe(
            cbRejected,
            boolean.fold(
              () => setUploadDialogDocument(document),
              () => props.onApplicationRejected()
            )
          )
        );
      })
    )();
  };

  const onDocumentUpload = (file: File) => {
    function submit(fileContent: FileContent, fileType: DocumentMime) {
      return pipe(
        uploadDialogDocument,
        option.fold(
          () => taskEither.left("GenericError" as const),
          document =>
            pipe(
              uploadDocument({
                requiredDocumentMetadata: {
                  applicationElementID: document.metadata.applicationElementID,
                  clientID: document.metadata.clientID,
                  documentTypeID: document.metadata.documentTypeID,
                  documentTypeName: document.metadata.documentTypeName,
                  section: document.metadata.section,
                  translationCode: document.metadata.translationCode,
                },
                documentContent: fileContent,
                filename: file.name,
                mimeType: fileType,
              }),
              taskEither.mapLeft(() => "GenericError" as const)
            )
        )
      );
    }
    return pipe(
      checkFileSize(file.size),
      either.chainW(() => {
        let type = file.type;
        if (file.type.trim().length === 0 && file.name.endsWith(".msg")) {
          type = "application/vnd.ms-outlook";
        }
        return decodeFileType(type);
      }),
      taskEither.fromEither,
      taskEither.chainW(fileType =>
        pipe(
          encodeFileToBase64(file),
          taskEither.map(fileBase64 => ({ fileType, fileBase64 }))
        )
      ),
      taskEither.chainW(scope =>
        pipe(
          submit(scope.fileBase64, scope.fileType),
          taskEither.map(submitResult => ({ ...scope, ...submitResult }))
        )
      ),
      taskEither.chainW(scope =>
        taskEither.fromIOEither(() =>
          pipe(
            mapSubmitDocumentStatus(scope.status),
            either.map(() => scope)
          )
        )
      )
    );
  };

  const onDocumentConfirm = (note: Option<string>) =>
    pipe(
      confirmDocumentUpload({
        uploadNote: option.isSome(note) ? note.value : "",
      }),
      taskEither.mapLeft<unknown, DocumentNoteUploadError>(
        () => "GenericError"
      ),
      taskEither.chain(response =>
        taskEither.fromIOEither(() => mapSubmitNoteStatus(response.status))
      )
    );

  const onOptionalDocumentUpload = (file: File) => {
    function submit(fileContent: FileContent, fileType: DocumentMime) {
      return pipe(
        checkNewOptionalDocument({
          documentContent: fileContent,
          filename: file.name,
          mimeType: fileType,
        }),
        taskEither.mapLeft(() => "GenericError" as const)
      );
    }
    return pipe(
      checkFileSize(file.size),
      either.chainW(() => {
        let type = file.type;
        if (file.type.trim().length === 0 && file.name.endsWith(".msg")) {
          type = "application/vnd.ms-outlook";
        }
        return decodeFileType(type);
      }),
      taskEither.fromEither,
      taskEither.chainW(fileType =>
        pipe(
          encodeFileToBase64(file),
          taskEither.map(fileBase64 => ({ fileType, fileBase64 }))
        )
      ),
      taskEither.chainW(scope =>
        pipe(
          submit(scope.fileBase64, scope.fileType),
          taskEither.map(submitResult => ({ ...scope, ...submitResult }))
        )
      ),
      taskEither.chainW(scope =>
        taskEither.fromIOEither(() =>
          pipe(
            mapSubmitDocumentStatus(scope.status),
            either.map(() => scope)
          )
        )
      )
    );
  };

  const onOptionalDocumentConfirm = (note: Option<string>) =>
    pipe(
      confirmOptionalDocument({
        uploadNote: option.isSome(note) ? note.value : "",
      }),
      taskEither.mapLeft<unknown, DocumentNoteUploadError>(
        () => "GenericError"
      ),
      taskEither.chain(response =>
        taskEither.fromIOEither(() => mapSubmitNoteStatus(response.status))
      ),
      taskEither.chain(() =>
        taskEither.fromIO(() => {
          props.refreshList();
        })
      )
    );

  const onDocumentCancel = () => {
    return pipe(
      uploadDialogDocument,
      option.map(document =>
        pipe(
          cancelUpload({
            applicationElementID: document.metadata.applicationElementID,
            clientID: document.metadata.clientID,
            documentTypeID: document.metadata.documentTypeID,
            documentTypeName: document.metadata.documentTypeName,
            section: document.metadata.section,
            translationCode: document.metadata.translationCode,
          })()
        )
      )
    );
  };

  function getFileSizeMax(): number {
    return 10;
  }

  function checkFileSize(fileSize: number) {
    return fileSize < getFileSizeMax() * 1024 * 1024
      ? either.right(undefined)
      : either.left("UnsupportedDocumentSize" as const);
  }

  const loadingError = () => (
    <ErrorBanner>
      {formatMessage("StandardLoan.UploadDocuments.loadingError")}
    </ErrorBanner>
  );

  const renderNonOptionalSections = (
    documents: api.UploadedDocumentData[],
    section: api.SectionType
  ) => {
    return (
      <UploadDocumentsSection
        section={documents.filter(document => document.section === section)}
        sectionName={section}
        showMandatoryError={formSubmitted}
        onSelectDocument={document =>
          dispatchWithRejectionCheck(option.some(document))
        }
        onRemoveDocument={docId =>
          pipe(
            removeDocument({ docId }),
            taskEither.map(() => {
              props.refreshList();
            })
          )()
        }
      />
    );
  };

  const renderOptionalSection = (
    documents: api.UploadedDocumentData[],
    section: api.SectionType
  ) => {
    return (
      <OptionalDocumentsSection
        section={documents.filter(document => document.section === section)}
        sectionName={section}
        showMandatoryError={formSubmitted}
        onSelectDocument={document =>
          dispatchWithRejectionCheck(option.some(document))
        }
        onRemoveDocument={docId =>
          pipe(
            removeOptionalDocument({ docId }),
            taskEither.map(() => {
              props.refreshList();
            })
          )()
        }
        onAddNewOptionalDocument={() =>
          setUploadNewOptionalDocumentDialog(true)
        }
      />
    );
  };

  const renderSendUploadLink = (
    <ContentRow type="lateral-margins">
      <Body size="medium" weight="regular" align="left">
        {[
          formatMessage("StandardLoan.UploadDocuments.subTitle"),
          buttonLink(
            pipe(
              sendLinkToClient(),
              taskEither.chain(() =>
                taskEither.fromIO(() => {
                  showSentLinkConfirmation(true);
                  setIsLinkSent(true);
                })
              )
            ),
            pipe(
              isLinkSent,
              boolean.fold(
                () => formatMessage("StandardLoan.UploadDocuments.sendLink"),
                () => formatMessage("StandardLoan.UploadDocuments.resendLink")
              )
            )
          ),
        ]}
      </Body>
    </ContentRow>
  );

  return (
    <MainContent>
      <PageHeading
        title={formatMessage("StandardLoan.UploadDocuments.title")}
        hideOnMobile
      />

      {isMobileLayout && <Space units={8} />}

      {sentLinkConfirmation && (
        <FeedbackDialog
          type="success"
          title={formatMessage(
            "Mortgage.Dashboard.Documents.SendLinkDialog.SendLinkFeedbackDialog.title"
          )}
          subtitle={formatMessage(
            "Mortgage.Dashboard.Documents.SendLinkDialog.SendLinkFeedbackDialog.subtitle2"
          )}
          children={
            <Banner
              type="informative"
              actions={option.none}
              content={formatMessage(
                "Mortgage.Dashboard.Documents.SendLinkDialog.SendLinkFeedbackDialog.banner"
              )}
              title={option.none}
              onDismiss={option.none}
            />
          }
          cta={{
            label: formatMessage(
              "Mortgage.Dashboard.Documents.SendLinkDialog.SendLinkFeedbackDialog.buttonLabel"
            ),
            action: () => {
              showSentLinkConfirmation(false);
            },
          }}
        />
      )}

      <Stack column shrink units={8}>
        {!props.isClientFlow && renderSendUploadLink}
        {pipe(
          props.requiredDocuments,
          option.fold(
            () => (
              <Box hAlignContent="center">
                <Loader />
              </Box>
            ),
            documents => (
              <Stack units={8} column={true}>
                <ContentRow type="lateral-margins">
                  <Card grow>
                    <Stack column grow units={7} style={{ flexBasis: 0 }}>
                      <Heading size="medium" weight="medium">
                        {formatMessage(
                          "StandardLoan.UploadDocuments.Panel.title"
                        )}
                      </Heading>
                      {pipe(
                        documents,
                        extractSectionsFromDocuments,
                        option.fold(loadingError, sections => (
                          <Stack column units={12} divider>
                            {pipe(
                              sections,
                              array.map(
                                section =>
                                  section != "OptionalDocuments" &&
                                  renderNonOptionalSections(documents, section)
                              )
                            )}
                          </Stack>
                        ))
                      )}
                      {r6Enabled &&
                        renderOptionalSection(documents, "OptionalDocuments")}
                    </Stack>
                  </Card>
                </ContentRow>

                {formSubmitted &&
                  documents.filter(({ docId }) => option.isNone(docId)).length >
                    0 && (
                    <ContentRow type="lateral-margins">
                      <Banner
                        actions={option.none}
                        type="error"
                        content={formatMessage(
                          "StandardLoan.UploadDocuments.error"
                        )}
                        title={option.none}
                      />
                    </ContentRow>
                  )}

                <ContentRow type="lateral-margins">
                  <Box grow column={isMobileLayout} hAlignContent="right">
                    <LoadingButton
                      variant="primary"
                      type="submit"
                      labels={{
                        normal: formatMessage("Confirm.buttonLabel"),
                        success: formatMessage("Confirm.buttonLabel"),
                        error: formatMessage("Confirm.buttonLabel"),
                        loading: formatMessage("Confirm.buttonLabel"),
                      }}
                      size="default"
                      action={pipe(
                        documents,
                        array.filter(({ docId }) => option.isNone(docId)),
                        emptyDocs => emptyDocs.length === 0,
                        boolean.fold(
                          () =>
                            pipe(
                              taskEither.leftIO(() => {
                                setFormSubmitted(true);
                              })
                            ),
                          () =>
                            taskEither.fromIO(() => submitWithRejectionCheck())
                        )
                      )}
                    />
                  </Box>
                </ContentRow>
              </Stack>
            )
          )
        )}
      </Stack>
      {pipe(
        uploadDialogDocument,
        option.map(document => (
          <StandardLoanDocumentUploadDialog
            onDismiss={() => {
              setUploadDialogDocument(option.none);
              props.refreshList();
            }}
            onDocumentUpload={onDocumentUpload}
            maxLengthNote={option.none}
            onDocumentConfirm={onDocumentConfirm}
            onDocumentCancel={onDocumentCancel}
            selectedApplicant={selectedMainApplicant}
            skipNotes={false}
            acceptableFileTypes={fileTypes}
            docTypeId={document.metadata.documentTypeID}
            applicationElementID={option.some(
              document.metadata.applicationElementID
            )}
            fileSizeMax={getFileSizeMax()}
            productType={props.productType}
            documentName={document.documentName}
          />
        )),
        option.toUndefined
      )}
      {uploadNewOptionalDocumentDialog && (
        <OptionalDocumentUploadDialog
          onDismiss={() => {
            setUploadNewOptionalDocumentDialog(false);
            props.refreshList();
          }}
          onDocumentUpload={onOptionalDocumentUpload}
          maxLengthNote={option.none}
          onDocumentConfirm={onOptionalDocumentConfirm}
          onDocumentCancel={() => pipe(cancelOptionalDocument()())}
          selectedApplicant={selectedMainApplicant}
          skipNotes={false}
          acceptableFileTypes={fileTypes}
          applicationElementID={option.none}
          fileSizeMax={getFileSizeMax()}
          productType={props.productType}
          isNoteMandatory={true}
        />
      )}
    </MainContent>
  );
}

function extractSectionsFromDocuments(
  documents: api.UploadedDocumentData[]
): Option<NonEmptyArray<api.SectionType>> {
  const sections: api.SectionType[] = [];

  documents.forEach(
    document =>
      !sections.includes(document.section) && sections.push(document.section)
  );

  return nonEmptyArray.fromArray(sections);
}
