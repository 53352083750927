import { Option } from "fp-ts/Option";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import {
  DocumentUploadDialog,
  DocumentUploadDialogProps,
} from "../../Common/Dialogs/DocumentUploadDialog/DocumentUploadDialog";
import { UploadDocumentFlowType } from "../../globalDomain";

type Props = {
  applicationElementID: Option<NonEmptyString>;
  productType: Option<UploadDocumentFlowType>;
} & Omit<DocumentUploadDialogProps, "mobileUploadEnabled" | "docTypeId">;

export const OptionalDocumentUploadDialog = (props: Props) => {
  return <DocumentUploadDialog {...props} mobileUploadEnabled={false} />;
};
