import { IO } from "fp-ts/IO";
import { pipe, constFalse } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { ExistingClientAuthenticationMethod } from "../globalDomain";
import { option } from "fp-ts";
import { UserValidation } from "../Common/UserValidation/UserValidation";
import { useFormatMessage } from "../intl";

interface Props {
  onExit: Option<IO<unknown>>;
  authenticationMethod: Option<ExistingClientAuthenticationMethod>;
  children: JSX.Element | null;
  skipValidation?: boolean;
}

export function UserValidationWrapper(props: Props) {
  const formatMessage = useFormatMessage();

  return pipe(
    props.authenticationMethod,
    option.fold(
      () => <>{props.children}</>,
      authMethod => (
        <UserValidation
          skipValidation={props.skipValidation}
          isModal={false}
          dialogTitle={formatMessage("StandardLoan.Landing.Title")}
          onDialogDismiss={props.onExit}
          onDialogExit={props.onExit}
          validateUser={details =>
            pipe(
              props.authenticationMethod,
              option.fold(constFalse, () => {
                const hasSecurityDetails =
                  details.hasEmailAddress && details.hasPhoneNumber;

                if (authMethod === "ID") {
                  return hasSecurityDetails && details.isPhoneNumberVerified;
                } else {
                  return hasSecurityDetails;
                }
              })
            )
          }
        >
          {props.children}
        </UserValidation>
      )
    )
  );
}
